@charset "UTF-8";
@font-face {
  font-family: "Jacco Wilbrink Graphics";
  src: url("fonts/jaccowilbrink.eot");
  src: url("fonts/jaccowilbrink.eot?#iefix") format("embedded-opentype"), url("fonts/jaccowilbrink.woff") format("woff"), url("fonts/jaccowilbrink.ttf") format("truetype"), url("fonts/jaccowilbrink.svg#jaccowilbrink") format("svg");
  font-weight: normal;
  font-style: normal; }

/*
 * http://meyerweb.com/eric/tools/css/reset/
 * v2.0 | 20110126
 * License: none (public domain)
 */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

a {
  text-decoration: none;
  color: inherit; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes delayedFadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes openMenu {
  0% {
    opacity: 0;
    transform: scale(0.95); }
  50% {
    opacity: 1; }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/*
 * Global stylesheet
 */
html, body {
  height: 100%;
  width: 100%; }

body {
  font-size: 15px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  background-color: #FFFFFF;
  color: #1A1611;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ECE7E3;
  min-width: 360px;
  zoom: 1; }
  @media (max-width: 500px) {
    body {
      font-size: 14px; } }
  body.nav-open .container, body.info-show .container {
    height: 100vh;
    overflow: hidden; }
    body.nav-open .container > header, body.info-show .container > header {
      height: 0;
      /*.top {
					//position: fixed;
					//left: 0;
					//right: 0;
				}*/ }
      body.nav-open .container > header .logo, body.info-show .container > header .logo {
        z-index: 100;
        opacity: 0 !important;
        animation: none !important; }
  body.loaded > .container > header .logo {
    transform: none;
    top: 88px;
    opacity: 1; }
    @media (max-width: 1000px) {
      body.loaded > .container > header .logo {
        top: 80px; } }
    @media (max-width: 500px) {
      body.loaded > .container > header .logo {
        top: 70px; } }

a {
  outline: 0; }

body > .container {
  background-color: #1A1611;
  max-width: 2560px;
  min-width: 360px;
  box-shadow: 0 0 200px rgba(26, 22, 17, 0.3);
  margin-left: auto;
  margin-right: auto;
  min-height: 100%; }
  body > .container > header {
    position: fixed;
    z-index: 2000;
    left: 0;
    right: 0;
    font-size: 1.25em;
    text-shadow: 0px 0px 20px white;
    height: 128px;
    transition: background-color .5s; }
    @media (max-width: 1000px) {
      body > .container > header {
        height: 105px; } }
    @media (max-width: 500px) {
      body > .container > header {
        height: 75px; } }
    body > .container > header .top {
      zoom: 1;
      /* For IE 6/7 */
      padding-top: 36px;
      z-index: 1000;
      position: relative; }
      body > .container > header .top:before, body > .container > header .top:after {
        content: "";
        display: table; }
      body > .container > header .top:after {
        clear: both; }
      @media (max-width: 1000px) {
        body > .container > header .top {
          padding-top: 30px; } }
      @media (max-width: 500px) {
        body > .container > header .top {
          padding-top: 20px; } }
      body > .container > header .top a.phone,
      body > .container > header .top button.menu {
        user-select: none;
        border: none;
        background-color: transparent;
        font-size: inherit;
        font-family: inherit;
        text-transform: uppercase;
        outline: 0;
        cursor: pointer;
        text-shadow: inherit;
        position: relative;
        display: inline-block;
        line-height: 1em;
        vertical-align: middle; }
        @media (max-width: 650px) {
          body > .container > header .top a.phone,
          body > .container > header .top button.menu {
            text-indent: 100vw;
            white-space: nowrap;
            overflow: hidden;
            width: 30px;
            height: 30px;
            padding: 20px !important;
            box-sizing: content-box;
            margin-top: -20px;
            margin-left: -20px;
            margin-right: -20px; } }
        body > .container > header .top a.phone::before,
        body > .container > header .top button.menu::before {
          content: '';
          display: block;
          font-family: "Jacco Wilbrink Graphics";
          font-weight: 400 !important;
          text-transform: none !important;
          color: #776E66;
          font-size: 18px;
          text-transform: none;
          position: absolute;
          width: 1em;
          height: 1em;
          line-height: 1em;
          text-indent: 0;
          top: 0.06em;
          margin-left: auto;
          margin-right: auto; }
          @media (max-width: 650px) {
            body > .container > header .top a.phone::before,
            body > .container > header .top button.menu::before {
              top: 20px;
              left: 20px !important;
              right: 20px !important;
              font-size: 23px; } }
        body > .container > header .top a.phone:hover::before,
        body > .container > header .top button.menu:hover::before {
          color: #1A1611; }
      body > .container > header .top a.phone {
        padding-left: 1.7875em;
        padding-left: calc(1.1875em + 14px); }
        body > .container > header .top a.phone::before {
          font-family: "Jacco Wilbrink Graphics" !important;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          left: 0; }
      body > .container > header .top button.menu {
        float: right;
        padding-right: 1.7875em;
        padding-right: calc(1.1875em + 14px); }

@keyframes menuOpen {
  0% {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  50% {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    transform: scale(0.05); }
  100% {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; } }

@keyframes menuClose {
  0% {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  50% {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    transform: scale(0.05); }
  100% {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; } }
        body > .container > header .top button.menu::before {
          font-family: "Jacco Wilbrink Graphics" !important;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          right: 0; }
        body > .container > header .top button.menu.closed::before {
          animation: menuClose .5s; }
        body > .container > header .top button.menu.open::before {
          animation: menuOpen .3s;
          font-family: "Jacco Wilbrink Graphics" !important;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 16px; }
    body > .container > header .logo {
      position: absolute;
      transform: translateX(-50%);
      z-index: 900;
      width: 365px;
      left: 50%;
      text-align: center;
      z-index: 2000;
      transition: opacity .5s;
      transform-origin: 50% 50%; }
      @media (max-width: 1000px) {
        body > .container > header .logo {
          width: 323.79032px; } }
      @media (max-width: 500px) {
        body > .container > header .logo {
          width: 235.48387px; } }
      body > .container > header .logo svg {
        width: 365px;
        height: 62px; }
        @media (max-width: 1000px) {
          body > .container > header .logo svg {
            height: 55px;
            width: 323.79032px; } }
        @media (max-width: 500px) {
          body > .container > header .logo svg {
            height: 40px;
            width: 235.48387px; } }
  body > .container > footer {
    background-color: #1A1611;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    color: #FFFFFF;
    text-align: center;
    position: relative; }
    body > .container > footer section {
      padding-top: 36px;
      padding-bottom: 36px; }
    @media (max-width: 750px) {
      body > .container > footer ul.social {
        margin-bottom: 16px; }
        body > .container > footer ul.social li a::before {
          font-size: 40px; } }
    body > .container > footer .numbers {
      color: #776E66;
      background-color: #000000;
      text-transform: uppercase;
      letter-spacing: .1em;
      font-size: 1.25em;
      line-height: 58px;
      user-select: none; }
      @media (max-width: 1000px) {
        body > .container > footer .numbers {
          display: none; } }
      body > .container > footer .numbers .number {
        display: inline-block;
        margin-left: 32px;
        margin-right: 32px; }
        body > .container > footer .numbers .number span {
          color: #FFFFFF;
          font-weight: 700;
          display: inline-block;
          text-align: right; }
    body > .container > footer .contact {
      position: relative; }
      @media (max-width: 1400px) {
        body > .container > footer .contact .content {
          margin-left: 32px;
          margin-right: 32px;
          box-sizing: border-box; } }
      body > .container > footer .contact::before {
        content: '';
        display: block;
        position: absolute;
        width: 112px;
        height: 112px;
        background-size: 112px 112px;
        top: -53px;
        left: 120px; }
        @media (max-width: 1400px) {
          body > .container > footer .contact::before {
            display: none; } }
        @media (min-width: 1401px) {
          body > .container > footer .contact::before {
            background-image: url("images/emblem-footer.png"); } }
      body > .container > footer .contact a {
        transition: color .2s; }
        body > .container > footer .contact a:hover {
          color: #776E66; }
      body > .container > footer .contact .tagline {
        display: block;
        font-size: 3.625em;
        font-family: "IM Fell DW Pica SC";
        color: #A49D95;
        margin-bottom: 40px;
        margin-top: 20px;
        text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2); }
        body > .container > footer .contact .tagline::before {
          content: '#';
          display: inline-block;
          vertical-align: top;
          font-size: .6em;
          margin-left: -.6em; }
        body > .container > footer .contact .tagline .alternative {
          color: #776E66; }
        @media (max-width: 750px) {
          body > .container > footer .contact .tagline {
            display: none; } }
      body > .container > footer .contact address, body > .container > footer .contact small {
        display: inline-block;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 0.78125em;
        letter-spacing: .1em; }
        @media (max-width: 1000px) {
          body > .container > footer .contact address, body > .container > footer .contact small {
            display: block; } }
        body > .container > footer .contact address .year, body > .container > footer .contact small .year {
          display: none; }
        @media (max-width: 600px) {
          body > .container > footer .contact address .year, body > .container > footer .contact small .year {
            display: inline; }
          body > .container > footer .contact address .author, body > .container > footer .contact small .author {
            display: none; } }
      body > .container > footer .contact address li {
        display: inline-block; }
        body > .container > footer .contact address li::after {
          content: '|';
          color: #A49D95;
          margin-left: 24px;
          margin-right: 24px; }
        body > .container > footer .contact address li:last-child::after {
          display: none; }
        @media (max-width: 1000px) {
          body > .container > footer .contact address li {
            display: block;
            line-height: 2.3em; }
            body > .container > footer .contact address li::after {
              display: none; } }
      body > .container > footer .contact small {
        color: #A49D95; }
        body > .container > footer .contact small::before {
          content: '/';
          margin-left: 24px;
          margin-right: 24px; }
        @media (max-width: 1400px) {
          body > .container > footer .contact small {
            margin-top: 1.75em;
            font-size: 0.6875em;
            display: block; }
            body > .container > footer .contact small::before {
              display: none; } }
    body > .container > footer a.to-top {
      font-size: 0.8125em;
      color: #776E66;
      text-transform: uppercase;
      font-weight: 700;
      transform: rotateZ(90deg) rotateY(180deg) rotateX(180deg) translateX(100%);
      transform-origin: center;
      display: block;
      position: absolute;
      right: 2px;
      right: calc(14px - 1em);
      bottom: 16px;
      user-select: none; }
      @media (max-width: 1400px) {
        body > .container > footer a.to-top {
          left: 20px;
          right: auto;
          bottom: 20px;
          transform: rotateZ(90deg) rotateY(180deg) rotateX(180deg) translateX(50%) translateY(-200%); } }
      body > .container > footer a.to-top:hover {
        color: #ECE7E3; }
    body > .container > footer .web-label {
      background-image: url("images/web-label.svg");
      background-size: 25px 31px;
      height: 31px;
      width: 25px;
      background-repeat: no-repeat;
      display: block;
      z-index: 500;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      right: 16px;
      bottom: 0; }
  body > .container > footer .social li,
  body > .container > section.menu .social li {
    display: inline-block; }
    body > .container > footer .social li a,
    body > .container > section.menu .social li a {
      display: block;
      text-indent: 200%;
      overflow: hidden;
      width: 44px;
      height: 44px;
      position: relative;
      color: #776E66;
      transition: transform .3s; }
      @media (max-width: 750px) {
        body > .container > footer .social li a,
        body > .container > section.menu .social li a {
          width: 50px;
          height: 50px; } }
      body > .container > footer .social li a::before,
      body > .container > section.menu .social li a::before {
        content: '';
        display: block;
        font-family: "Jacco Wilbrink Graphics";
        font-weight: 400 !important;
        text-transform: none !important;
        text-indent: 0;
        font-size: 36px;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        padding: 4px; }
      body > .container > footer .social li a:hover,
      body > .container > section.menu .social li a:hover {
        color: #ECE7E3; }
    body > .container > footer .social li.instagram a::before,
    body > .container > section.menu .social li.instagram a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
    body > .container > footer .social li.facebook-automotive a::before,
    body > .container > section.menu .social li.facebook-automotive a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
    body > .container > footer .social li.facebook-people a::before,
    body > .container > section.menu .social li.facebook-people a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
    body > .container > footer .social li.flickr a::before,
    body > .container > section.menu .social li.flickr a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
  body > .container > section.menu {
    position: fixed;
    overflow: auto;
    background-color: #FFFFFF;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    max-width: none;
    transition: opacity .25s, transform .2s;
    z-index: 900; }
    body > .container > section.menu .content {
      margin: auto;
      padding-top: 100px;
      padding-bottom: 50px;
      opacity: 0;
      transition: opacity 3s, background-color 5s; }
      @media (max-width: 1100px) {
        body > .container > section.menu .content {
          padding-top: 100px;
          padding-bottom: 50px; } }
    body > .container > section.menu.open {
      display: flex;
      opacity: 1;
      visibility: visible; }
      body > .container > section.menu.open .content {
        animation: openMenu .5s forwards;
        animation-delay: .1s; }
    body > .container > section.menu .navigation {
      zoom: 1;
      /* For IE 6/7 */ }
      body > .container > section.menu .navigation:before, body > .container > section.menu .navigation:after {
        content: "";
        display: table; }
      body > .container > section.menu .navigation:after {
        clear: both; }
      @media (max-width: 1100px) {
        body > .container > section.menu .navigation {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse; } }
      body > .container > section.menu .navigation .social, body > .container > section.menu .navigation nav {
        width: 50%;
        float: left;
        padding-left: 72px;
        padding-right: 72px;
        box-sizing: border-box;
        position: relative; }
        @media (max-width: 1100px) {
          body > .container > section.menu .navigation .social, body > .container > section.menu .navigation nav {
            width: auto;
            padding-left: 0px;
            padding-right: 0px; } }
      body > .container > section.menu .navigation .social {
        text-align: right; }
        body > .container > section.menu .navigation .social .tagline {
          font-family: "IM Fell DW Pica SC";
          font-size: 5.9375em;
          line-height: .57em;
          width: 320px;
          display: block;
          color: #1A1611;
          word-wrap: break-word;
          -webkit-hyphenate-character: "";
          hyphenate-character: "";
          margin-bottom: 56px;
          user-select: none; }
          body > .container > section.menu .navigation .social .tagline::before {
            content: '#';
            display: inline-block;
            vertical-align: top;
            font-size: .6em;
            margin-left: -.6em; }
          body > .container > section.menu .navigation .social .tagline::before {
            font-size: .5em;
            margin-top: -.45em; }
          body > .container > section.menu .navigation .social .tagline .alternative {
            color: #A49D95; }
        @media (max-width: 1100px) {
          body > .container > section.menu .navigation .social {
            margin-top: 75px;
            text-align: center; }
            body > .container > section.menu .navigation .social .tagline {
              display: none; } }
        body > .container > section.menu .navigation .social ul li {
          display: inline-block; }
          body > .container > section.menu .navigation .social ul li a {
            width: 36px;
            height: 36px; }
            body > .container > section.menu .navigation .social ul li a::before {
              font-size: 30px;
              color: #A49D95; }
            body > .container > section.menu .navigation .social ul li a:hover::before {
              color: #776E66;
              transform: translateY(-3px); }
            @media (max-width: 1100px) {
              body > .container > section.menu .navigation .social ul li a {
                width: 50px;
                height: 50px; }
                body > .container > section.menu .navigation .social ul li a::before {
                  font-size: 42px;
                  color: #776E66; }
                body > .container > section.menu .navigation .social ul li a:hover::before {
                  color: #1A1611;
                  transform: initial; } }
        body > .container > section.menu .navigation .social .tagline,
        body > .container > section.menu .navigation .social ul {
          float: right;
          clear: both; }
          @media (max-width: 1100px) {
            body > .container > section.menu .navigation .social .tagline,
            body > .container > section.menu .navigation .social ul {
              float: none; } }
      body > .container > section.menu .navigation nav {
        text-align: left; }
        body > .container > section.menu .navigation nav::after {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          border: 1px solid #A49D95;
          left: -1px;
          right: auto; }
        @media (max-width: 1100px) {
          body > .container > section.menu .navigation nav::after {
            display: none; } }
        @media (max-width: 1100px) {
          body > .container > section.menu .navigation nav ul {
            padding-left: 18px; } }
        body > .container > section.menu .navigation nav ul li {
          font-size: 27px;
          text-transform: uppercase;
          display: block;
          margin-bottom: 48px;
          letter-spacing: .06em;
          white-space: nowrap;
          line-height: 38px; }
          @media (max-width: 600px) {
            body > .container > section.menu .navigation nav ul li {
              font-size: 25px;
              margin-bottom: 35px; } }
          body > .container > section.menu .navigation nav ul li:last-child {
            margin-bottom: 0; }
          body > .container > section.menu .navigation nav ul li a {
            padding-left: 28px; }
            body > .container > section.menu .navigation nav ul li a::before {
              font-family: "Jacco Wilbrink Graphics";
              font-weight: 400 !important;
              text-transform: none !important;
              color: #A49D95;
              position: relative;
              top: 0;
              display: block;
              float: left;
              font-size: 28px; }
            body > .container > section.menu .navigation nav ul li a::after {
              font-family: "Jacco Wilbrink Graphics" !important;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "";
              display: inline-block;
              transition: transform .3s;
              margin-left: .5em;
              height: 1em;
              width: .5em;
              vertical-align: top;
              font-size: .5em;
              font-weight: bold; }
            body > .container > section.menu .navigation nav ul li a.galleries::before {
              font-family: "Jacco Wilbrink Graphics" !important;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: ""; }
            body > .container > section.menu .navigation nav ul li a.intro::before {
              font-family: "Jacco Wilbrink Graphics" !important;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: ""; }
            body > .container > section.menu .navigation nav ul li a.about::before {
              font-family: "Jacco Wilbrink Graphics" !important;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: ""; }
            body > .container > section.menu .navigation nav ul li a.contact::before {
              font-family: "Jacco Wilbrink Graphics" !important;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: ""; }
            body > .container > section.menu .navigation nav ul li a:hover::before {
              color: #776E66; }
            body > .container > section.menu .navigation nav ul li a:hover::after {
              transform: translateX(0.2em); }
    body > .container > section.menu footer {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      text-align: center;
      left: 0;
      right: 0;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .1em;
      margin-top: 170px; }
      @media (max-height: 1000px) {
        body > .container > section.menu footer {
          margin-top: 110px; } }
      @media (max-height: 700px) {
        body > .container > section.menu footer {
          margin-top: 70px; } }
      @media (max-height: 600px), (max-width: 1100px) {
        body > .container > section.menu footer {
          display: none; } }
      body > .container > section.menu footer address {
        font-size: 0.875em;
        margin-bottom: 24px; }
        body > .container > section.menu footer address ul li {
          display: inline-block; }
          body > .container > section.menu footer address ul li::after {
            content: "|";
            margin-left: 2em;
            margin-right: 2em;
            color: #A49D95; }
          body > .container > section.menu footer address ul li:last-child::after {
            display: none; }
          body > .container > section.menu footer address ul li a {
            transition: color .2s; }
            body > .container > section.menu footer address ul li a:hover {
              color: #776E66; }
      body > .container > section.menu footer small {
        font-size: 0.6875em;
        color: #A49D95; }
  body > .container > section.page {
    background-color: #FFFFFF;
    overflow: hidden; }
    body > .container > section.page .hero {
      padding-top: 320px;
      text-align: center;
      height: 290px;
      color: #FFFFFF;
      white-space: pre-line;
      background-size: cover;
      background-position: center;
      background-position: top; }
      body > .container > section.page .hero .image {
        transition: opacity 1s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover; }
      body > .container > section.page .hero .overlay .tagline,
      body > .container > section.page .hero .overlay .button {
        z-index: 1;
        position: relative; }
      body > .container > section.page .hero .tagline {
        font-size: 3.625em;
        padding-bottom: .06em;
        padding-top: .03em;
        padding-left: 0.7em;
        padding-right: 0.7em;
        font-family: "IM Fell DW Pica SC";
        background-color: rgba(26, 22, 17, 0.6);
        clear: both;
        margin-bottom: 16px;
        text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
        white-space: nowrap; }
        body > .container > section.page .hero .tagline::before {
          content: '#';
          display: inline-block;
          vertical-align: top;
          font-size: .6em;
          margin-left: -.6em; }
        @media (max-width: 750px) {
          body > .container > section.page .hero .tagline {
            font-size: 2.2em;
            font-size: 8vw;
            padding-top: .1em;
            padding-bottom: .2em; } }
      body > .container > section.page .hero .button {
        margin-top: 1.4em; }
        @media (max-width: 750px) {
          body > .container > section.page .hero .button {
            margin-top: 1.6em; } }

noscript.enable {
  text-align: center;
  color: #FFFFFF;
  font-family: inherit;
  padding: 30px;
  display: block;
  margin: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: darkred;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  z-index: 2000; }

.wrapper {
  max-width: 2560px;
  width: 100%;
  min-width: 360px;
  margin-left: auto;
  margin-right: auto; }

.wrapper > .content {
  margin-left: 36px;
  margin-right: 36px; }
  @media (max-width: 1300px) {
    .wrapper > .content {
      margin-left: 30px;
      margin-right: 30px; } }
  @media (max-width: 750px) {
    .wrapper > .content {
      margin-left: 20px;
      margin-right: 20px; } }

a.button,
button.button {
  border: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: 0;
  color: #ECE7E3;
  background-color: #1A1611;
  text-transform: uppercase;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.875em;
  padding-bottom: 0.875em;
  display: inline-block;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: .15em;
  font-size: 1.0625em;
  text-align: center;
  position: relative;
  z-index: 0;
  transition: background-color .5s, transform .1s, box-shadow .5s; }
  @media (max-width: 750px) {
    a.button,
    button.button {
      font-size: 1.2em; } }
  @media (max-width: 500px) {
    a.button,
    button.button {
      font-size: 1.1em; } }
  a.button:hover,
  button.button:hover {
    background-color: rgba(26, 22, 17, 0.8);
    box-shadow: 0 0 60px 0 #000000 inset; }
  a.button:active,
  button.button:active {
    transform: translateY(4px); }

/*
 * Page specific styles
 */
body.intro {
  background-color: #FFFFFF; }
  body.intro::before,
  body.intro .spinner {
    user-select: none;
    display: block;
    content: "";
    color: #1A1611;
    border: 4px solid #1A1611;
    border-top: 4px solid transparent;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    animation: delayedFadeIn .75s linear 0s 1, spin 1s linear infinite;
    margin: auto;
    position: relative;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
    font-size: 32px;
    font-family: "Jacco Wilbrink Graphics";
    font-weight: 400 !important;
    text-transform: none !important; }
  body.intro.loaded::before,
  body.intro.loaded .spinner {
    display: none; }
  body.intro.loading-error::before,
  body.intro.loading-error .spinner {
    animation: none;
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    border-color: transparent; }
  body.intro::before {
    position: absolute;
    z-index: 2000;
    top: 50%;
    margin-top: -24px;
    left: 50%;
    margin-left: -16px;
    transition: top .75s, margin-top .5s; }
  @media (max-width: 1000px) {
    body.intro::before {
      width: 30px;
      height: 30px;
      border-width: 3px; } }
  body.intro.loaded > .container > header .logo {
    top: 88px;
    opacity: 1;
    transition: top .75s ease-out, width .5s, height .5s, opacity .5s; }
    body.intro.loaded > .container > header .logo.loaded-animation {
      animation: fadeIn 1s forwards;
      animation-timing-function: ease-in; }
    @media (max-width: 1000px) {
      body.intro.loaded > .container > header .logo {
        top: 80px; } }
    @media (max-width: 500px) {
      body.intro.loaded > .container > header .logo {
        top: 70px; } }
  body.intro.loaded > .container > .page {
    animation: fadeIn 1s forwards; }
  body.intro > .container {
    background-color: transparent; }
    body.intro > .container > header {
      position: absolute; }
      body.intro > .container > header .logo {
        top: 30vh;
        top: calc(30vh - 60px);
        transform: translateX(-50%);
        opacity: 0; }
    @media (max-width: 500px) {
      body.intro > .container > header .logo,
      body.intro > .container > header .logo svg {
        height: 45px;
        width: 219px; } }
    body.intro > .container > .page {
      opacity: 0;
      background-color: transparent;
      transition: opacity .5s; }
      body.intro > .container > .page > .hero {
        z-index: 0;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        min-height: 600px;
        box-sizing: border-box;
        padding-top: 50vh; }
        @media (max-height: 600px) {
          body.intro > .container > .page > .hero {
            padding-top: 300px; } }
        body.intro > .container > .page > .hero ul.slides li.slide {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
        body.intro > .container > .page > .hero ul.slides.active li.slide {
          opacity: 0; }
          body.intro > .container > .page > .hero ul.slides.active li.slide::before,
          body.intro > .container > .page > .hero ul.slides.active li.slide .spinner {
            user-select: none;
            display: block;
            content: "";
            color: #1A1611;
            border: 4px solid #1A1611;
            border-top: 4px solid transparent;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            animation: delayedFadeIn .75s linear 0s 1, spin 1s linear infinite;
            margin: auto;
            position: relative;
            text-align: center;
            line-height: 40px;
            vertical-align: middle;
            font-size: 32px;
            font-family: "Jacco Wilbrink Graphics";
            font-weight: 400 !important;
            text-transform: none !important; }
          body.intro > .container > .page > .hero ul.slides.active li.slide.loaded::before,
          body.intro > .container > .page > .hero ul.slides.active li.slide.loaded .spinner {
            display: none; }
          body.intro > .container > .page > .hero ul.slides.active li.slide.loading-error::before,
          body.intro > .container > .page > .hero ul.slides.active li.slide.loading-error .spinner {
            animation: none;
            font-family: "Jacco Wilbrink Graphics" !important;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "";
            border-color: transparent; }
          body.intro > .container > .page > .hero ul.slides.active li.slide::before {
            content: "";
            display: block;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 50%; }
          body.intro > .container > .page > .hero ul.slides.active li.slide.transition {
            transition: opacity 1.5s; }
          body.intro > .container > .page > .hero ul.slides.active li.slide.active {
            opacity: 1; }
        body.intro > .container > .page > .hero .dashboard {
          min-height: auto; }
          @media (max-width: 1000px) {
            body.intro > .container > .page > .hero .dashboard {
              display: none; } }
          body.intro > .container > .page > .hero .dashboard .button {
            bottom: -80px; }
        body.intro > .container > .page > .hero .overlay {
          z-index: 100;
          position: relative;
          margin-top: -3.3em;
          margin-left: auto;
          margin-right: auto;
          max-width: 750px; }
          @media (max-width: 750px) {
            body.intro > .container > .page > .hero .overlay {
              max-width: none;
              margin-left: 20px;
              margin-right: 20px; } }
        body.intro > .container > .page > .hero .slides .slide {
          background-size: cover;
          background-position: top;
          position: fixed; }
    body.intro > .container > footer {
      display: none; }

body.intro > .container,
body.gallery > .container {
  background-color: transparent; }
  body.intro > .container > header,
  body.gallery > .container > header {
    position: absolute; }

body.intro .hero .dashboard,
body.gallery .gallery .dashboard {
  min-height: 470px;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }
  body.intro .hero .dashboard .button,
  body.gallery .gallery .dashboard .button {
    height: 48px;
    width: 48px;
    padding: 16px;
    display: block;
    text-align: center;
    display: flex;
    font-family: "Jacco Wilbrink Graphics";
    font-weight: 400 !important;
    text-transform: none !important;
    color: #FFFFFF;
    text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    background-color: transparent;
    font-size: 48px;
    position: absolute;
    z-index: 800;
    bottom: 0;
    text-shadow: 0px 0px 5px black;
    transition: padding .3s, background-color .5s, transform .1s, box-shadow .5s;
    animation: none; }
    @media (min-height: 731px) {
      body.intro .hero .dashboard .button,
      body.gallery .gallery .dashboard .button {
        bottom: -40px; } }
    body.intro .hero .dashboard .button:hover,
    body.gallery .gallery .dashboard .button:hover {
      transform: initial;
      box-shadow: none; }
      body.intro .hero .dashboard .button:hover.left,
      body.gallery .gallery .dashboard .button:hover.left {
        padding-left: 13px;
        padding-right: 19px; }
      body.intro .hero .dashboard .button:hover.right,
      body.gallery .gallery .dashboard .button:hover.right {
        padding-right: 13px;
        padding-left: 19px; }
    body.intro .hero .dashboard .button::before,
    body.gallery .gallery .dashboard .button::before {
      margin: auto;
      display: block;
      content: "";
      animation: none;
      border: 0;
      transition: initial;
      position: static;
      opacity: 1;
      visibility: visible; }
    body.intro .hero .dashboard .button.left,
    body.gallery .gallery .dashboard .button.left {
      left: 8px; }
      body.intro .hero .dashboard .button.left::before,
      body.gallery .gallery .dashboard .button.left::before {
        font-family: "Jacco Wilbrink Graphics" !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
    body.intro .hero .dashboard .button.right,
    body.gallery .gallery .dashboard .button.right {
      right: 8px; }
      body.intro .hero .dashboard .button.right::before,
      body.gallery .gallery .dashboard .button.right::before {
        font-family: "Jacco Wilbrink Graphics" !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }

@media (max-height: 730px) {
  body.gallery .gallery .dashboard .button.left, body.gallery .gallery .dashboard .button.right {
    bottom: initial;
    top: 50vh;
    transform: translateY(-50%); }
  body.gallery .gallery .dashboard .button.right {
    right: 67px; } }

body.galleries_index > .container > section.page > main a.button,
body.galleries > .container > section.page > main a.button {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 700; }
  @media (min-width: 621px) {
    body.galleries_index > .container > section.page > main a.button,
    body.galleries > .container > section.page > main a.button {
      display: none; } }
  body.galleries_index > .container > section.page > main a.button::after,
  body.galleries > .container > section.page > main a.button::after {
    content: ">";
    margin-left: .5em; }
  body.galleries_index > .container > section.page > main a.button > .alternative,
  body.galleries > .container > section.page > main a.button > .alternative {
    color: #776E66; }

body.galleries_index > .container > section.page > .hero .overlay .button,
body.galleries_index > .container > section.page > .hero .overlay .tagline,
body.galleries > .container > section.page > .hero .overlay .button,
body.galleries > .container > section.page > .hero .overlay .tagline,
body.gallery > .container > section.page > .hero .overlay .button,
body.gallery > .container > section.page > .hero .overlay .tagline,
body.contact > .container > section.page > .hero .overlay .button,
body.contact > .container > section.page > .hero .overlay .tagline,
body.about > .container > section.page > .hero .overlay .button,
body.about > .container > section.page > .hero .overlay .tagline,
body.intro > .container > section.page > .hero .overlay .button,
body.intro > .container > section.page > .hero .overlay .tagline {
  opacity: 0;
  transition: opacity .5s, transform 1s, background-color .5s, box-shadow .5s; }

body.galleries_index > .container > section.page > .hero .overlay .button,
body.galleries > .container > section.page > .hero .overlay .button,
body.gallery > .container > section.page > .hero .overlay .button,
body.contact > .container > section.page > .hero .overlay .button,
body.about > .container > section.page > .hero .overlay .button,
body.intro > .container > section.page > .hero .overlay .button {
  transform: scale(0.9); }

body.galleries_index > .container > section.page > .hero.loaded .overlay .button,
body.galleries_index > .container > section.page > .hero.loaded .overlay .tagline,
body.galleries > .container > section.page > .hero.loaded .overlay .button,
body.galleries > .container > section.page > .hero.loaded .overlay .tagline,
body.gallery > .container > section.page > .hero.loaded .overlay .button,
body.gallery > .container > section.page > .hero.loaded .overlay .tagline,
body.contact > .container > section.page > .hero.loaded .overlay .button,
body.contact > .container > section.page > .hero.loaded .overlay .tagline,
body.about > .container > section.page > .hero.loaded .overlay .button,
body.about > .container > section.page > .hero.loaded .overlay .tagline,
body.intro > .container > section.page > .hero.loaded .overlay .button,
body.intro > .container > section.page > .hero.loaded .overlay .tagline {
  opacity: 1; }

body.galleries_index > .container > section.page > .hero.loaded .overlay .button,
body.galleries > .container > section.page > .hero.loaded .overlay .button,
body.gallery > .container > section.page > .hero.loaded .overlay .button,
body.contact > .container > section.page > .hero.loaded .overlay .button,
body.about > .container > section.page > .hero.loaded .overlay .button,
body.intro > .container > section.page > .hero.loaded .overlay .button {
  transform: scale(1); }

body.galleries_index > .container > section.page > .hero,
body.galleries > .container > section.page > .hero,
body.gallery > .container > section.page > .hero,
body.contact > .container > section.page > .hero,
body.about > .container > section.page > .hero {
  position: relative; }
  body.galleries_index > .container > section.page > .hero::before,
  body.galleries_index > .container > section.page > .hero .spinner,
  body.galleries > .container > section.page > .hero::before,
  body.galleries > .container > section.page > .hero .spinner,
  body.gallery > .container > section.page > .hero::before,
  body.gallery > .container > section.page > .hero .spinner,
  body.contact > .container > section.page > .hero::before,
  body.contact > .container > section.page > .hero .spinner,
  body.about > .container > section.page > .hero::before,
  body.about > .container > section.page > .hero .spinner {
    user-select: none;
    display: block;
    content: "";
    color: #1A1611;
    border: 4px solid #1A1611;
    border-top: 4px solid transparent;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    animation: delayedFadeIn .75s linear 0s 1, spin 1s linear infinite;
    margin: auto;
    position: relative;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
    font-size: 32px;
    font-family: "Jacco Wilbrink Graphics";
    font-weight: 400 !important;
    text-transform: none !important; }
  body.galleries_index > .container > section.page > .hero.loaded::before,
  body.galleries_index > .container > section.page > .hero.loaded .spinner,
  body.galleries > .container > section.page > .hero.loaded::before,
  body.galleries > .container > section.page > .hero.loaded .spinner,
  body.gallery > .container > section.page > .hero.loaded::before,
  body.gallery > .container > section.page > .hero.loaded .spinner,
  body.contact > .container > section.page > .hero.loaded::before,
  body.contact > .container > section.page > .hero.loaded .spinner,
  body.about > .container > section.page > .hero.loaded::before,
  body.about > .container > section.page > .hero.loaded .spinner {
    display: none; }
  body.galleries_index > .container > section.page > .hero.loading-error::before,
  body.galleries_index > .container > section.page > .hero.loading-error .spinner,
  body.galleries > .container > section.page > .hero.loading-error::before,
  body.galleries > .container > section.page > .hero.loading-error .spinner,
  body.gallery > .container > section.page > .hero.loading-error::before,
  body.gallery > .container > section.page > .hero.loading-error .spinner,
  body.contact > .container > section.page > .hero.loading-error::before,
  body.contact > .container > section.page > .hero.loading-error .spinner,
  body.about > .container > section.page > .hero.loading-error::before,
  body.about > .container > section.page > .hero.loading-error .spinner {
    animation: none;
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    border-color: transparent; }
  body.galleries_index > .container > section.page > .hero::before,
  body.galleries > .container > section.page > .hero::before,
  body.gallery > .container > section.page > .hero::before,
  body.contact > .container > section.page > .hero::before,
  body.about > .container > section.page > .hero::before {
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    position: absolute; }
  body.galleries_index > .container > section.page > .hero .image,
  body.galleries > .container > section.page > .hero .image,
  body.gallery > .container > section.page > .hero .image,
  body.contact > .container > section.page > .hero .image,
  body.about > .container > section.page > .hero .image {
    opacity: 0; }
    body.galleries_index > .container > section.page > .hero .image.loaded,
    body.galleries > .container > section.page > .hero .image.loaded,
    body.gallery > .container > section.page > .hero .image.loaded,
    body.contact > .container > section.page > .hero .image.loaded,
    body.about > .container > section.page > .hero .image.loaded {
      opacity: 1; }

body.galleries_index > .container > header .logo,
body.galleries > .container > header .logo,
body.gallery > .container > header .logo {
  top: 32px; }
  @media (max-width: 1000px) {
    body.galleries_index > .container > header .logo,
    body.galleries > .container > header .logo,
    body.gallery > .container > header .logo {
      top: 25px; } }
  @media (max-width: 500px) {
    body.galleries_index > .container > header .logo,
    body.galleries > .container > header .logo,
    body.gallery > .container > header .logo {
      top: 20px; } }

body.galleries_index > .container > section.page,
body.galleries > .container > section.page,
body.gallery > .container > section.page {
  padding-top: 128px; }
  @media (max-width: 1000px) {
    body.galleries_index > .container > section.page,
    body.galleries > .container > section.page,
    body.gallery > .container > section.page {
      padding-top: 105px; } }
  @media (max-width: 500px) {
    body.galleries_index > .container > section.page,
    body.galleries > .container > section.page,
    body.gallery > .container > section.page {
      padding-top: 75px; } }
  body.galleries_index > .container > section.page > .hero,
  body.galleries > .container > section.page > .hero,
  body.gallery > .container > section.page > .hero {
    padding: 0;
    height: 600px;
    background-color: #ECE7E3; }
    @media (max-width: 1600px) {
      body.galleries_index > .container > section.page > .hero,
      body.galleries > .container > section.page > .hero,
      body.gallery > .container > section.page > .hero {
        height: 30vw; } }
    @media (max-width: 750px) {
      body.galleries_index > .container > section.page > .hero,
      body.galleries > .container > section.page > .hero,
      body.gallery > .container > section.page > .hero {
        height: 33vw;
        margin: 0; } }
  body.galleries_index > .container > section.page > main,
  body.galleries > .container > section.page > main,
  body.gallery > .container > section.page > main {
    text-align: center; }
    body.galleries_index > .container > section.page > main .overview,
    body.galleries > .container > section.page > main .overview,
    body.gallery > .container > section.page > main .overview {
      padding-bottom: 45px; }
      @media (max-width: 1300px) {
        body.galleries_index > .container > section.page > main .overview,
        body.galleries > .container > section.page > main .overview,
        body.gallery > .container > section.page > main .overview {
          padding-bottom: 30px; } }
      @media (max-width: 750px) {
        body.galleries_index > .container > section.page > main .overview,
        body.galleries > .container > section.page > main .overview,
        body.gallery > .container > section.page > main .overview {
          padding-bottom: 20px; } }

body.galleries_index > .container > header,
body.galleries > .container > header {
  background-color: rgba(255, 255, 255, 0.95); }

@media (min-width: 621px) {
  body.galleries > .container main {
    margin: -1px; } }

body.galleries > .container main > .galleries {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-bottom: 45px; }
  @media (max-width: 1300px) {
    body.galleries > .container main > .galleries {
      padding-bottom: 30px; } }
  @media (max-width: 750px) {
    body.galleries > .container main > .galleries {
      padding-bottom: 20px; } }
  @media (max-width: 620px) {
    body.galleries > .container main > .galleries {
      margin: 0px;
      padding: 0; } }
  @media (min-width: 621px) {
    body.galleries > .container main > .galleries .row {
      display: flex;
      flex-direction: row; } }
  @media (max-width: 1000px) {
    body.galleries > .container main > .galleries .row {
      flex-wrap: wrap; } }
  body.galleries > .container main > .galleries .row.large-image .column {
    height: 660px; }
    @media (max-width: 1990px) {
      body.galleries > .container main > .galleries .row.large-image .column {
        height: 36vw; } }

@media (max-width: 1000px) and (min-width: 621px) {
  body.galleries > .container main > .galleries .row.large-image .column {
    max-width: 100%;
    width: 100%;
    flex-direction: row;
    height: auto; }
    body.galleries > .container main > .galleries .row.large-image .column.large .item {
      width: 50%;
      max-width: 50%;
      height: 26vw; }
    body.galleries > .container main > .galleries .row.large-image .column:nth-child(2) {
      flex-wrap: wrap;
      flex-direction: row;
      position: relative; }
      body.galleries > .container main > .galleries .row.large-image .column:nth-child(2) .item:nth-child(2) {
        width: 50%;
        max-width: 50%;
        max-width: calc(50% - 4.5px);
        position: absolute;
        right: 0;
        margin-top: -26vw;
        margin-top: calc(-26vw - 1px); }
      body.galleries > .container main > .galleries .row.large-image .column:nth-child(2) .item.text {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        order: 2; }
    body.galleries > .container main > .galleries .row.large-image .column .item {
      width: 50%; } }
    @media (max-width: 620px) {
      body.galleries > .container main > .galleries .row.large-image .column {
        height: auto; } }
  @media (min-width: 1001px) {
    body.galleries > .container main > .galleries .row.large-image:nth-child(2n-1) .column {
      order: 1; }
    body.galleries > .container main > .galleries .row.large-image:nth-child(2n-1) .column:first-child {
      order: 2; } }
  body.galleries > .container main > .galleries .row.wide-text .text {
    background-color: transparent; }
    body.galleries > .container main > .galleries .row.wide-text .text .tagline {
      text-transform: none;
      width: 90%;
      font-family: "IM Fell DW Pica SC";
      font-size: 3.5em;
      margin: auto; }
      body.galleries > .container main > .galleries .row.wide-text .text .tagline::before {
        content: '#';
        display: inline-block;
        vertical-align: top;
        font-size: .6em;
        margin-left: -.6em; }
    body.galleries > .container main > .galleries .row.wide-text .text ul {
      display: none; }
  @media (max-width: 620px) {
    body.galleries > .container main > .galleries .row.wide-text {
      display: none; } }
  body.galleries > .container main > .galleries .row.regular .item {
    max-width: 33.33333%;
    max-width: calc((1 / 3) * 100% - 2px); }

@media (max-width: 1000px) and (min-width: 621px) {
  body.galleries > .container main > .galleries .row.regular .item {
    max-width: 50%;
    max-width: calc(50% - 2px);
    order: 1; }
    body.galleries > .container main > .galleries .row.regular .item:first-child {
      max-width: 100%;
      flex-basis: 100%;
      order: 2; } }
  @media (max-width: 620px) {
    body.galleries > .container main > .galleries .row.regular .item,
    body.galleries > .container main > .galleries .row.regular .column {
      width: 100%;
      max-width: 100%; } }
  body.galleries > .container main > .galleries .item,
  body.galleries > .container main > .galleries .column {
    height: 330px;
    flex: 1; }
    @media (max-width: 1990px) {
      body.galleries > .container main > .galleries .item,
      body.galleries > .container main > .galleries .column {
        height: 18vw; } }

@media (max-width: 1000px) and (min-width: 621px) {
  body.galleries > .container main > .galleries .item,
  body.galleries > .container main > .galleries .column {
    height: 26vw; }
    body.galleries > .container main > .galleries .item:first-child,
    body.galleries > .container main > .galleries .column:first-child {
      height: 40vw; } }
    @media (max-width: 620px) {
      body.galleries > .container main > .galleries .item,
      body.galleries > .container main > .galleries .column {
        height: auto;
        height: calc(55vw + 100px);
        overflow: visible; } }
  body.galleries > .container main > .galleries .column {
    display: flex;
    flex-direction: column; }
    body.galleries > .container main > .galleries .column.large {
      flex: 2; }
      @media (max-width: 1000px) {
        body.galleries > .container main > .galleries .column.large {
          flex-basis: 100%; } }
    @media (max-width: 620px) {
      body.galleries > .container main > .galleries .column {
        display: block; } }
  body.galleries > .container main > .galleries .item {
    text-align: left;
    position: relative;
    margin: 1px;
    text-transform: uppercase;
    background-color: #ECE7E3; }
    body.galleries > .container main > .galleries .item.text {
      display: flex;
      flex-flow: column;
      text-align: center; }
      body.galleries > .container main > .galleries .item.text .tagline {
        font-size: 1.75em;
        width: 50%;
        margin: auto;
        margin-bottom: 39px; }
        @media (max-width: 1300px) and (min-width: 1001px) {
          body.galleries > .container main > .galleries .item.text .tagline {
            margin-bottom: auto;
            font-size: 1.5em; } }
      body.galleries > .container main > .galleries .item.text ul {
        margin: auto;
        margin-top: 0;
        width: 66%;
        font-size: 0.875em;
        font-weight: 700; }
        body.galleries > .container main > .galleries .item.text ul li {
          line-height: 1.4em; }
          body.galleries > .container main > .galleries .item.text ul li a:hover {
            color: #776E66; }
        @media (min-width: 1301px) {
          body.galleries > .container main > .galleries .item.text ul li {
            display: inline-block; }
            body.galleries > .container main > .galleries .item.text ul li::after {
              content: "|";
              margin-left: .5em;
              margin-right: .5em;
              color: #776E66; }
            body.galleries > .container main > .galleries .item.text ul li:last-child::after {
              display: none; } }
      @media (max-width: 620px) {
        body.galleries > .container main > .galleries .item.text {
          display: none; } }
    body.galleries > .container main > .galleries .item.gallery {
      transition: background-color 1s; }
      body.galleries > .container main > .galleries .item.gallery::before,
      body.galleries > .container main > .galleries .item.gallery .spinner {
        user-select: none;
        display: block;
        content: "";
        color: #FFFFFF;
        border: 4px solid #FFFFFF;
        border-top: 4px solid transparent;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        animation: delayedFadeIn .75s linear 0s 1, spin 1s linear infinite;
        margin: auto;
        position: relative;
        text-align: center;
        line-height: 32px;
        vertical-align: middle;
        font-size: 24px;
        font-family: "Jacco Wilbrink Graphics";
        font-weight: 400 !important;
        text-transform: none !important; }
      body.galleries > .container main > .galleries .item.gallery.loaded::before,
      body.galleries > .container main > .galleries .item.gallery.loaded .spinner {
        display: none; }
      body.galleries > .container main > .galleries .item.gallery.loading-error::before,
      body.galleries > .container main > .galleries .item.gallery.loading-error .spinner {
        animation: none;
        font-family: "Jacco Wilbrink Graphics" !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        border-color: transparent; }
      body.galleries > .container main > .galleries .item.gallery::before {
        top: calc(50% - 34px); }
        @media (max-width: 620px) {
          body.galleries > .container main > .galleries .item.gallery::before {
            top: calc(50% - 72px); } }
      body.galleries > .container main > .galleries .item.gallery:hover .image {
        filter: brightness(50%); }
      body.galleries > .container main > .galleries .item.gallery .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        opacity: 0;
        transition: opacity 1s, filter 1s; }
        @media (max-width: 620px) {
          body.galleries > .container main > .galleries .item.gallery .image {
            height: 55vw; } }
        body.galleries > .container main > .galleries .item.gallery .image.visible {
          opacity: 1; }
      body.galleries > .container main > .galleries .item.gallery.loading-error {
        background-color: #776E66; }
      body.galleries > .container main > .galleries .item.gallery .meta {
        background-color: rgba(26, 22, 17, 0.85);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px;
        font-weight: 700;
        font-size: 0.875em;
        color: #ECE7E3; }
        body.galleries > .container main > .galleries .item.gallery .meta .image-count {
          float: right;
          color: #A49D95; }
          body.galleries > .container main > .galleries .item.gallery .meta .image-count .count {
            color: #ECE7E3; }
        @media (max-width: 620px) {
          body.galleries > .container main > .galleries .item.gallery .meta {
            background-color: #1A1611;
            height: 100px;
            padding: 0;
            margin-top: 2px;
            font-size: 1em;
            line-height: 28px;
            padding-top: 22px;
            padding-bottom: 22px;
            box-sizing: border-box;
            text-align: center; }
            body.galleries > .container main > .galleries .item.gallery .meta > span {
              display: block;
              white-space: nowrap; }
            body.galleries > .container main > .galleries .item.gallery .meta .image-count {
              float: none;
              font-size: 0.875em;
              color: #776E66; }
              body.galleries > .container main > .galleries .item.gallery .meta .image-count .count {
                color: inherit; } }
    @media (max-width: 620px) {
      body.galleries > .container main > .galleries .item {
        margin: 0;
        margin-top: 20px; } }

body.galleries_index > .container > section.page > main .overview .categories {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: 1px; }
  @media (max-width: 1300px) {
    body.galleries_index > .container > section.page > main .overview .categories {
      margin-left: -10px;
      margin-right: -10px;
      margin-top: 0px; } }
  @media (max-width: 620px) {
    body.galleries_index > .container > section.page > main .overview .categories {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px; } }
  body.galleries_index > .container > section.page > main .overview .categories li {
    display: block;
    width: 33.33333%;
    display: block;
    overflow: hidden;
    flex-grow: 1; }
    @media (max-width: 1300px) {
      body.galleries_index > .container > section.page > main .overview .categories li {
        width: 50%;
        margin-top: 20px; } }
    @media (max-width: 620px) {
      body.galleries_index > .container > section.page > main .overview .categories li {
        width: 100%; } }
    body.galleries_index > .container > section.page > main .overview .categories li a {
      margin-left: 1px;
      margin-right: 1px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row; }
      @media (max-width: 1300px) {
        body.galleries_index > .container > section.page > main .overview .categories li a {
          margin-left: 10px;
          margin-right: 10px; }
          body.galleries_index > .container > section.page > main .overview .categories li a::before {
            order: 2; }
          body.galleries_index > .container > section.page > main .overview .categories li a::after {
            order: 1; } }
      @media (max-width: 620px) {
        body.galleries_index > .container > section.page > main .overview .categories li a {
          margin-left: 0;
          margin-right: 0; } }
      body.galleries_index > .container > section.page > main .overview .categories li a::before, body.galleries_index > .container > section.page > main .overview .categories li a::after {
        transition: filter .5s, color .5s, background-color .5s; }
      body.galleries_index > .container > section.page > main .overview .categories li a::before {
        display: block;
        height: 160px;
        background-color: #FFFFFF;
        content: "";
        width: 100%;
        margin-top: 1px;
        margin-bottom: 1px;
        font-family: "Jacco Wilbrink Graphics";
        font-weight: 400 !important;
        text-transform: none !important;
        font-size: 200px;
        text-align: center;
        line-height: 160px;
        vertical-align: middle;
        overflow: hidden;
        color: #1A1611;
        font-weight: normal !important; }
        @media (max-width: 1300px) {
          body.galleries_index > .container > section.page > main .overview .categories li a::before {
            height: 125px;
            line-height: 125px;
            font-size: 160px; } }
        @media (max-width: 500px) {
          body.galleries_index > .container > section.page > main .overview .categories li a::before {
            height: 100px;
            line-height: 100px;
            font-size: 140px; } }
      body.galleries_index > .container > section.page > main .overview .categories li a::after {
        display: block;
        height: 400px;
        min-height: 200px;
        content: "";
        width: 100%;
        margin-top: 1px;
        margin-bottom: 1px;
        background-size: cover;
        background-position: center;
        animation: fadeIn 1s forwards;
        opacity: 0; }
        @media (max-width: 1600px) {
          body.galleries_index > .container > section.page > main .overview .categories li a::after {
            height: 20vw; } }
        @media (max-width: 750px) {
          body.galleries_index > .container > section.page > main .overview .categories li a::after {
            height: 26vw; } }
        @media (max-width: 620px) {
          body.galleries_index > .container > section.page > main .overview .categories li a::after {
            height: 55vw; } }
      body.galleries_index > .container > section.page > main .overview .categories li a span {
        display: none; }
      body.galleries_index > .container > section.page > main .overview .categories li a:hover::after {
        filter: brightness(70%); }
      body.galleries_index > .container > section.page > main .overview .categories li a:hover:hover::before {
        color: #776E66;
        background-color: #A49D95; }
    body.galleries_index > .container > section.page > main .overview .categories li.automotive a::before, body.galleries_index > .container > section.page > main .overview .categories li.purelife a::before {
      order: 2; }
    body.galleries_index > .container > section.page > main .overview .categories li.automotive a::after, body.galleries_index > .container > section.page > main .overview .categories li.purelife a::after {
      order: 1; }
    body.galleries_index > .container > section.page > main .overview .categories li.purelife a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      background-color: #1A1611;
      color: #A49D95; }
    body.galleries_index > .container > section.page > main .overview .categories li.purelife a::after {
      background-image: url("images/galleries/thumbs/purelife.jpg"); }
    body.galleries_index > .container > section.page > main .overview .categories li.people a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      background-color: #1A1611;
      color: #FFFFFF; }
    body.galleries_index > .container > section.page > main .overview .categories li.people a::after {
      background-image: url("images/galleries/thumbs/people.jpg"); }
    body.galleries_index > .container > section.page > main .overview .categories li.animals a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      background-color: #776E66;
      color: #FFFFFF; }
    body.galleries_index > .container > section.page > main .overview .categories li.animals a::after {
      background-image: url("images/galleries/thumbs/animals.jpg"); }
    body.galleries_index > .container > section.page > main .overview .categories li.commercial a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      background-color: #ECE7E3; }
    body.galleries_index > .container > section.page > main .overview .categories li.commercial a::after {
      background-image: url("images/galleries/thumbs/commercial.jpg"); }
    body.galleries_index > .container > section.page > main .overview .categories li.wedding a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      background-color: #ECE7E3; }
    body.galleries_index > .container > section.page > main .overview .categories li.wedding a::after {
      background-image: url("images/galleries/thumbs/wedding.jpg"); }
    body.galleries_index > .container > section.page > main .overview .categories li.automotive a::before {
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }

@media (max-width: 1300px) and (min-width: 751px) {
  body.galleries_index > .container > section.page > main .overview .categories li.automotive a::before {
    background-color: #FAFAFA; } }
    body.galleries_index > .container > section.page > main .overview .categories li.automotive a::after {
      background-image: url("images/galleries/thumbs/automotive.jpg"); }

body.gallery.info-show {
  overflow: hidden; }

@media (min-width: 751px) and (min-height: 520px) and (orientation: landscape) {
  body.gallery > .container > header,
  body.gallery > .container > footer {
    display: none; } }

body.gallery > .container > section.page > main nav {
  position: absolute;
  top: 87px;
  right: 0;
  z-index: 800; }
  @media (max-height: 520px) and (min-width: 1000px) {
    body.gallery > .container > section.page > main nav {
      top: 130px; } }
  @media (max-width: 1000px) {
    body.gallery > .container > section.page > main nav {
      top: 107px; } }
  @media (max-width: 500px) {
    body.gallery > .container > section.page > main nav {
      top: 77px; } }
  @media (max-width: 751px) and (min-height: 500px) and (orientation: portrait) {
    body.gallery > .container > section.page > main nav.sticky {
      top: 2px;
      position: fixed; } }
  body.gallery > .container > section.page > main nav > a,
  body.gallery > .container > section.page > main nav > button,
  body.gallery > .container > section.page > main nav > span {
    background-color: #1A1611;
    color: #ECE7E3;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0;
    font-size: 1.625em;
    width: 60px;
    height: 60px;
    overflow: hidden;
    display: flex;
    margin-bottom: 2px;
    outline: 0; }
    body.gallery > .container > section.page > main nav > a::before,
    body.gallery > .container > section.page > main nav > button::before,
    body.gallery > .container > section.page > main nav > span::before {
      font-family: "Jacco Wilbrink Graphics";
      font-weight: 400 !important;
      text-transform: none !important; }
    body.gallery > .container > section.page > main nav > a span,
    body.gallery > .container > section.page > main nav > button span,
    body.gallery > .container > section.page > main nav > span span {
      margin: auto; }
    body.gallery > .container > section.page > main nav > a.back,
    body.gallery > .container > section.page > main nav > button.back,
    body.gallery > .container > section.page > main nav > span.back {
      cursor: pointer; }
      body.gallery > .container > section.page > main nav > a.back::before,
      body.gallery > .container > section.page > main nav > button.back::before,
      body.gallery > .container > section.page > main nav > span.back::before {
        font-family: "Jacco Wilbrink Graphics" !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        margin: auto;
        font-size: .85em; }
    body.gallery > .container > section.page > main nav > a.info,
    body.gallery > .container > section.page > main nav > button.info,
    body.gallery > .container > section.page > main nav > span.info {
      cursor: pointer; }
      body.gallery > .container > section.page > main nav > a.info::before,
      body.gallery > .container > section.page > main nav > button.info::before,
      body.gallery > .container > section.page > main nav > span.info::before {
        font-family: "Jacco Wilbrink Graphics" !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        margin: auto; }
    body.gallery > .container > section.page > main nav > a.back:hover, body.gallery > .container > section.page > main nav > a.info:hover,
    body.gallery > .container > section.page > main nav > button.back:hover,
    body.gallery > .container > section.page > main nav > button.info:hover,
    body.gallery > .container > section.page > main nav > span.back:hover,
    body.gallery > .container > section.page > main nav > span.info:hover {
      background-color: #36312b; }
    body.gallery > .container > section.page > main nav > a.index,
    body.gallery > .container > section.page > main nav > button.index,
    body.gallery > .container > section.page > main nav > span.index {
      color: #776E66;
      display: none; }
      @media (min-width: 751px) and (min-height: 520px) and (orientation: landscape) {
        body.gallery > .container > section.page > main nav > a.index,
        body.gallery > .container > section.page > main nav > button.index,
        body.gallery > .container > section.page > main nav > span.index {
          display: flex; } }
    body.gallery > .container > section.page > main nav > a.index, body.gallery > .container > section.page > main nav > a.length,
    body.gallery > .container > section.page > main nav > button.index,
    body.gallery > .container > section.page > main nav > button.length,
    body.gallery > .container > section.page > main nav > span.index,
    body.gallery > .container > section.page > main nav > span.length {
      font-family: "Roboto";
      font-weight: 300; }
  @media (max-width: 600px) {
    body.gallery > .container > section.page > main nav > a,
    body.gallery > .container > section.page > main nav > button,
    body.gallery > .container > section.page > main nav > span {
      font-size: 1.5em; } }

body.gallery > .container > section.page > main section.info {
  background-color: white;
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  overflow-y: auto;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: fixed !important;
  font-family: "Roboto Condensed"; }
  body.gallery > .container > section.page > main section.info .content {
    margin: auto;
    text-align: center;
    opacity: 0;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-left: 48px;
    margin-right: 48px; }
    @media (max-width: 750px) {
      body.gallery > .container > section.page > main section.info .content {
        margin-left: 40px;
        margin-right: 40px; } }
    body.gallery > .container > section.page > main section.info .content h1 {
      font-size: 3.5em;
      font-family: "IM Fell DW Pica SC";
      line-height: .733em;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; }
      @media (max-width: 750px) {
        body.gallery > .container > section.page > main section.info .content h1 {
          font-size: 2.5em; } }
    body.gallery > .container > section.page > main section.info .content p {
      margin-top: 3em;
      margin-bottom: 3em;
      line-height: 1.6875em;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto; }
      body.gallery > .container > section.page > main section.info .content p:first-of-type {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.375em;
        letter-spacing: .1em; }
  body.gallery > .container > section.page > main section.info.show {
    visibility: visible;
    animation: openMenu .5s forwards; }
    body.gallery > .container > section.page > main section.info.show .content {
      visibility: visible;
      animation: openMenu .5s forwards;
      animation-delay: .1s; }

body.gallery > .container > section.page > main .gallery {
  text-align: center; }
  body.gallery > .container > section.page > main .gallery .dashboard {
    display: none; }
    @media (min-width: 751px) and (min-height: 520px) and (orientation: landscape) {
      body.gallery > .container > section.page > main .gallery .dashboard {
        display: block; } }
  body.gallery > .container > section.page > main .gallery ul.slides li.slide {
    position: relative;
    user-select: none; }
    body.gallery > .container > section.page > main .gallery ul.slides li.slide::before,
    body.gallery > .container > section.page > main .gallery ul.slides li.slide .spinner {
      user-select: none;
      display: block;
      content: "";
      color: #1A1611;
      border: 4px solid #1A1611;
      border-top: 4px solid transparent;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      animation: delayedFadeIn .75s linear 0s 1, spin 1s linear infinite;
      margin: auto;
      position: relative;
      text-align: center;
      line-height: 40px;
      vertical-align: middle;
      font-size: 32px;
      font-family: "Jacco Wilbrink Graphics";
      font-weight: 400 !important;
      text-transform: none !important; }
    body.gallery > .container > section.page > main .gallery ul.slides li.slide.loaded::before,
    body.gallery > .container > section.page > main .gallery ul.slides li.slide.loaded .spinner {
      display: none; }
    body.gallery > .container > section.page > main .gallery ul.slides li.slide.loading-error::before,
    body.gallery > .container > section.page > main .gallery ul.slides li.slide.loading-error .spinner {
      animation: none;
      font-family: "Jacco Wilbrink Graphics" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      border-color: transparent; }
    body.gallery > .container > section.page > main .gallery ul.slides li.slide::before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px; }
    body.gallery > .container > section.page > main .gallery ul.slides li.slide a {
      cursor: zoom-in; }
    body.gallery > .container > section.page > main .gallery ul.slides li.slide img {
      width: 100%;
      height: auto;
      opacity: 0;
      transition: opacity .5s; }
    body.gallery > .container > section.page > main .gallery ul.slides li.slide.loaded img {
      opacity: 1;
      height: auto !important; }
  @media (min-width: 751px) and (min-height: 520px) and (orientation: landscape) {
    body.gallery > .container > section.page > main .gallery ul.slides.active {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000000; }
      body.gallery > .container > section.page > main .gallery ul.slides.active li.slide {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        overflow: hidden;
        opacity: 0; }
        body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.transition {
          transition: opacity .5s; }
        body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active {
          opacity: 1;
          z-index: 100; }
          body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active::before,
          body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active .spinner {
            user-select: none;
            display: block;
            content: "";
            color: #FFFFFF;
            border: 4px solid #FFFFFF;
            border-top: 4px solid transparent;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            animation: delayedFadeIn .75s linear 0s 1, spin 1s linear infinite;
            margin: auto;
            position: relative;
            text-align: center;
            line-height: 40px;
            vertical-align: middle;
            font-size: 32px;
            font-family: "Jacco Wilbrink Graphics";
            font-weight: 400 !important;
            text-transform: none !important; }
          body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active.loaded::before,
          body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active.loaded .spinner {
            display: none; }
          body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active.loading-error::before,
          body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active.loading-error .spinner {
            animation: none;
            font-family: "Jacco Wilbrink Graphics" !important;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "";
            border-color: transparent; }
          body.gallery > .container > section.page > main .gallery ul.slides.active li.slide.active::before {
            position: absolute; }
        body.gallery > .container > section.page > main .gallery ul.slides.active li.slide a {
          margin: auto;
          cursor: default; }
        body.gallery > .container > section.page > main .gallery ul.slides.active li.slide img {
          width: auto;
          max-height: 100%;
          max-width: 100%;
          max-height: 100vh;
          max-width: 100vw; } }

@media (min-width: 1001px) {
  html:not(.no-js) body.contact > .container > section.page > main > section,
  html:not(.no-js) body.about > .container > section.page > main > section {
    opacity: 0;
    transform: translateY(30px);
    transition: transform .7s ease-out, opacity .5s ease-in; }
    html:not(.no-js) body.contact > .container > section.page > main > section.animate,
    html:not(.no-js) body.about > .container > section.page > main > section.animate {
      opacity: 1;
      transform: translateY(0); } }

@media (max-width: 900px) {
  body.contact > .container > header,
  body.about > .container > header {
    background-color: rgba(255, 255, 255, 0.95); } }

@media (max-width: 900px) {
  body.contact > .container > header .logo,
  body.about > .container > header .logo {
    top: 25px; } }

@media (max-width: 500px) {
  body.contact > .container > header .logo,
  body.about > .container > header .logo {
    top: 20px; } }

@media (max-width: 900px) {
  body.contact > .container > section.page,
  body.about > .container > section.page {
    padding-top: 105px; } }

@media (max-width: 500px) {
  body.contact > .container > section.page,
  body.about > .container > section.page {
    padding-top: 75px; } }

body.contact > .container > section.page > .hero,
body.about > .container > section.page > .hero {
  position: relative; }
  body.contact > .container > section.page > .hero .image,
  body.about > .container > section.page > .hero .image {
    z-index: 0; }
  @media (max-width: 1300px) {
    body.contact > .container > section.page > .hero,
    body.about > .container > section.page > .hero {
      padding-top: 30vw;
      height: 25vw; } }
  @media (max-width: 1000px) {
    body.contact > .container > section.page > .hero,
    body.about > .container > section.page > .hero {
      padding-top: 25vw;
      height: 30vw; } }
  @media (max-width: 900px) {
    body.contact > .container > section.page > .hero,
    body.about > .container > section.page > .hero {
      padding: 0;
      height: 45vw; }
      body.contact > .container > section.page > .hero .tagline,
      body.contact > .container > section.page > .hero .button,
      body.about > .container > section.page > .hero .tagline,
      body.about > .container > section.page > .hero .button {
        display: none; } }
  @media (max-width: 500px) {
    body.contact > .container > section.page > .hero,
    body.about > .container > section.page > .hero {
      padding: 0;
      height: 60vw;
      margin: 0; } }

body.contact > .container > section.page > main > .banners ul,
body.about > .container > section.page > main > .banners ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 2px;
  height: 480px;
  margin: -1px; }
  @media (max-width: 1300px) {
    body.contact > .container > section.page > main > .banners ul,
    body.about > .container > section.page > main > .banners ul {
      height: 35vw; } }
  @media (max-width: 1000px) {
    body.contact > .container > section.page > main > .banners ul,
    body.about > .container > section.page > main > .banners ul {
      height: 60vw; } }
  @media (max-width: 750px) {
    body.contact > .container > section.page > main > .banners ul,
    body.about > .container > section.page > main > .banners ul {
      height: auto; } }
  body.contact > .container > section.page > main > .banners ul li,
  body.about > .container > section.page > main > .banners ul li {
    height: 160px;
    width: 33.33333%;
    display: block;
    overflow: hidden;
    flex-grow: 1;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: .1em; }
    body.contact > .container > section.page > main > .banners ul li > a,
    body.contact > .container > section.page > main > .banners ul li > span,
    body.about > .container > section.page > main > .banners ul li > a,
    body.about > .container > section.page > main > .banners ul li > span {
      margin: 1px;
      display: flex;
      height: 160px;
      text-align: center;
      background-color: #A49D95;
      color: #FFFFFF;
      padding-left: 24px;
      padding-right: 24px;
      text-transform: uppercase; }
      body.contact > .container > section.page > main > .banners ul li > a svg,
      body.contact > .container > section.page > main > .banners ul li > span svg,
      body.about > .container > section.page > main > .banners ul li > a svg,
      body.about > .container > section.page > main > .banners ul li > span svg {
        width: auto;
        max-width: 70%;
        max-height: 12.5vw; }
        @media (max-width: 750px) {
          body.contact > .container > section.page > main > .banners ul li > a svg,
          body.contact > .container > section.page > main > .banners ul li > span svg,
          body.about > .container > section.page > main > .banners ul li > a svg,
          body.about > .container > section.page > main > .banners ul li > span svg {
            max-height: 30vw; } }
      body.contact > .container > section.page > main > .banners ul li > a span,
      body.contact > .container > section.page > main > .banners ul li > span span,
      body.about > .container > section.page > main > .banners ul li > a span,
      body.about > .container > section.page > main > .banners ul li > span span {
        line-height: .7em;
        display: block;
        margin: auto;
        font-weight: 700;
        width: 100%; }
        body.contact > .container > section.page > main > .banners ul li > a span::before,
        body.contact > .container > section.page > main > .banners ul li > span span::before,
        body.about > .container > section.page > main > .banners ul li > a span::before,
        body.about > .container > section.page > main > .banners ul li > span span::before {
          text-transform: none;
          font-family: "Jacco Wilbrink Graphics";
          font-weight: 400 !important;
          text-transform: none !important;
          margin-right: .75em;
          font-size: 1.2em;
          position: relative;
          top: .15em;
          color: #776E66; }
    @media (max-width: 1300px) {
      body.contact > .container > section.page > main > .banners ul li,
      body.about > .container > section.page > main > .banners ul li {
        height: 15vw; }
        body.contact > .container > section.page > main > .banners ul li > a,
        body.contact > .container > section.page > main > .banners ul li > span,
        body.about > .container > section.page > main > .banners ul li > a,
        body.about > .container > section.page > main > .banners ul li > span {
          height: 15vw; } }
    @media (max-width: 1000px) {
      body.contact > .container > section.page > main > .banners ul li,
      body.about > .container > section.page > main > .banners ul li {
        width: 50%;
        height: 20vw; }
        body.contact > .container > section.page > main > .banners ul li > a,
        body.contact > .container > section.page > main > .banners ul li > span,
        body.about > .container > section.page > main > .banners ul li > a,
        body.about > .container > section.page > main > .banners ul li > span {
          height: 20vw; } }
    @media (max-width: 750px) {
      body.contact > .container > section.page > main > .banners ul li,
      body.about > .container > section.page > main > .banners ul li {
        width: 100%;
        height: 22vw;
        min-height: 100px; }
        body.contact > .container > section.page > main > .banners ul li > a,
        body.contact > .container > section.page > main > .banners ul li > span,
        body.about > .container > section.page > main > .banners ul li > a,
        body.about > .container > section.page > main > .banners ul li > span {
          height: 22vw;
          min-height: 100px; } }
    body.contact > .container > section.page > main > .banners ul li:nth-child(1),
    body.about > .container > section.page > main > .banners ul li:nth-child(1) {
      order: 1; }
    body.contact > .container > section.page > main > .banners ul li:nth-child(2),
    body.about > .container > section.page > main > .banners ul li:nth-child(2) {
      order: 2; }
      body.contact > .container > section.page > main > .banners ul li:nth-child(2) > a,
      body.contact > .container > section.page > main > .banners ul li:nth-child(2) > span,
      body.about > .container > section.page > main > .banners ul li:nth-child(2) > a,
      body.about > .container > section.page > main > .banners ul li:nth-child(2) > span {
        background-color: #FFFFFF;
        color: #1A1611; }
    body.contact > .container > section.page > main > .banners ul li:nth-child(3),
    body.about > .container > section.page > main > .banners ul li:nth-child(3) {
      order: 3; }
      body.contact > .container > section.page > main > .banners ul li:nth-child(3) > a,
      body.contact > .container > section.page > main > .banners ul li:nth-child(3) > span,
      body.about > .container > section.page > main > .banners ul li:nth-child(3) > a,
      body.about > .container > section.page > main > .banners ul li:nth-child(3) > span {
        background-color: #776E66; }
    body.contact > .container > section.page > main > .banners ul li:nth-child(4),
    body.about > .container > section.page > main > .banners ul li:nth-child(4) {
      order: 4; }
      body.contact > .container > section.page > main > .banners ul li:nth-child(4) > a,
      body.contact > .container > section.page > main > .banners ul li:nth-child(4) > span,
      body.about > .container > section.page > main > .banners ul li:nth-child(4) > a,
      body.about > .container > section.page > main > .banners ul li:nth-child(4) > span {
        background-color: #1A1611;
        color: #ECE7E3; }
    body.contact > .container > section.page > main > .banners ul li:nth-child(5),
    body.about > .container > section.page > main > .banners ul li:nth-child(5) {
      order: 5; }
      body.contact > .container > section.page > main > .banners ul li:nth-child(5) > a,
      body.contact > .container > section.page > main > .banners ul li:nth-child(5) > span,
      body.about > .container > section.page > main > .banners ul li:nth-child(5) > a,
      body.about > .container > section.page > main > .banners ul li:nth-child(5) > span {
        background-color: #ECE7E3;
        color: #1A1611; }
    body.contact > .container > section.page > main > .banners ul li:nth-child(6),
    body.about > .container > section.page > main > .banners ul li:nth-child(6) {
      order: 6; }
      body.contact > .container > section.page > main > .banners ul li:nth-child(6) > a,
      body.contact > .container > section.page > main > .banners ul li:nth-child(6) > span,
      body.about > .container > section.page > main > .banners ul li:nth-child(6) > a,
      body.about > .container > section.page > main > .banners ul li:nth-child(6) > span {
        background-color: #FFFFFF;
        color: #1A1611; }
      @media (max-width: 1000px) {
        body.contact > .container > section.page > main > .banners ul li:nth-child(6),
        body.about > .container > section.page > main > .banners ul li:nth-child(6) {
          order: 4; } }
    body.contact > .container > section.page > main > .banners ul li.large,
    body.about > .container > section.page > main > .banners ul li.large {
      height: 320px; }
      body.contact > .container > section.page > main > .banners ul li.large > a,
      body.contact > .container > section.page > main > .banners ul li.large > span,
      body.about > .container > section.page > main > .banners ul li.large > a,
      body.about > .container > section.page > main > .banners ul li.large > span {
        height: 320px;
        background-size: cover;
        background-position: top; }
      @media (max-width: 1300px) {
        body.contact > .container > section.page > main > .banners ul li.large,
        body.about > .container > section.page > main > .banners ul li.large {
          height: 20vw; }
          body.contact > .container > section.page > main > .banners ul li.large > a,
          body.contact > .container > section.page > main > .banners ul li.large > span,
          body.about > .container > section.page > main > .banners ul li.large > a,
          body.about > .container > section.page > main > .banners ul li.large > span {
            height: 20vw; } }
      @media (max-width: 750px) {
        body.contact > .container > section.page > main > .banners ul li.large,
        body.about > .container > section.page > main > .banners ul li.large {
          height: 55vw;
          max-height: 300px; }
          body.contact > .container > section.page > main > .banners ul li.large > a,
          body.contact > .container > section.page > main > .banners ul li.large > span,
          body.about > .container > section.page > main > .banners ul li.large > a,
          body.about > .container > section.page > main > .banners ul li.large > span {
            height: 55vw;
            max-height: 300px; } }
    body.contact > .container > section.page > main > .banners ul li.alternative > a > span,
    body.contact > .container > section.page > main > .banners ul li.alternative > span > span,
    body.about > .container > section.page > main > .banners ul li.alternative > a > span,
    body.about > .container > section.page > main > .banners ul li.alternative > span > span {
      font-weight: 400;
      font-size: 1.125em; }
      body.contact > .container > section.page > main > .banners ul li.alternative > a > span > span,
      body.contact > .container > section.page > main > .banners ul li.alternative > span > span > span,
      body.about > .container > section.page > main > .banners ul li.alternative > a > span > span,
      body.about > .container > section.page > main > .banners ul li.alternative > span > span > span {
        text-transform: none;
        font-family: "IM Fell DW Pica SC";
        font-size: 2.77778em;
        margin-bottom: 12px; }
        @media (max-width: 1300px) {
          body.contact > .container > section.page > main > .banners ul li.alternative > a > span > span,
          body.contact > .container > section.page > main > .banners ul li.alternative > span > span > span,
          body.about > .container > section.page > main > .banners ul li.alternative > a > span > span,
          body.about > .container > section.page > main > .banners ul li.alternative > span > span > span {
            font-size: 2.8em; } }
        body.contact > .container > section.page > main > .banners ul li.alternative > a > span > span span,
        body.contact > .container > section.page > main > .banners ul li.alternative > span > span > span span,
        body.about > .container > section.page > main > .banners ul li.alternative > a > span > span span,
        body.about > .container > section.page > main > .banners ul li.alternative > span > span > span span {
          display: inline;
          color: #776E66;
          text-transform: lowercase; }
      body.contact > .container > section.page > main > .banners ul li.alternative > a > span span,
      body.contact > .container > section.page > main > .banners ul li.alternative > span > span span,
      body.about > .container > section.page > main > .banners ul li.alternative > a > span span,
      body.about > .container > section.page > main > .banners ul li.alternative > span > span span {
        font-weight: 400; }
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(1) > a,
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(1) > span, body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(2) > a,
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(2) > span,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(1) > a,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(1) > span,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(2) > a,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(2) > span {
      color: #A49D95; }
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(1) > a span > span,
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(1) > span span > span, body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(2) > a span > span,
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(2) > span span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(1) > a span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(1) > span span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(2) > a span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(2) > span span > span {
        color: #1A1611; }
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(3) > a,
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(3) > span, body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(4) > a,
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(4) > span,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(3) > a,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(3) > span,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(4) > a,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(4) > span {
      color: #776E66; }
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(3) > a span > span,
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(3) > span span > span, body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(4) > a span > span,
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(4) > span span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(3) > a span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(3) > span span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(4) > a span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(4) > span span > span {
        color: #ECE7E3; }
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(5) > a,
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(5) > span, body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(6) > a,
    body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(6) > span,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(5) > a,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(5) > span,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(6) > a,
    body.about > .container > section.page > main > .banners ul li.alternative:nth-child(6) > span {
      color: #776E66; }
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(5) > a > span > span,
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(5) > span > span > span, body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(6) > a > span > span,
      body.contact > .container > section.page > main > .banners ul li.alternative:nth-child(6) > span > span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(5) > a > span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(5) > span > span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(6) > a > span > span,
      body.about > .container > section.page > main > .banners ul li.alternative:nth-child(6) > span > span > span {
        color: #1A1611; }

body.contact > .container > section.page > main > .invitation,
body.contact > .container > section.page > main > .profile,
body.about > .container > section.page > main > .invitation,
body.about > .container > section.page > main > .profile {
  position: relative;
  overflow: hidden;
  padding-top: 96px;
  padding-bottom: 96px;
  min-height: 380px; }
  @media (max-width: 1600px) {
    body.contact > .container > section.page > main > .invitation,
    body.contact > .container > section.page > main > .profile,
    body.about > .container > section.page > main > .invitation,
    body.about > .container > section.page > main > .profile {
      min-height: 420px; } }
  @media (max-width: 1000px) {
    body.contact > .container > section.page > main > .invitation,
    body.contact > .container > section.page > main > .profile,
    body.about > .container > section.page > main > .invitation,
    body.about > .container > section.page > main > .profile {
      padding-bottom: 0; } }
  @media (max-width: 750px) {
    body.contact > .container > section.page > main > .invitation,
    body.contact > .container > section.page > main > .profile,
    body.about > .container > section.page > main > .invitation,
    body.about > .container > section.page > main > .profile {
      padding-top: 60px; } }
  body.contact > .container > section.page > main > .invitation .content,
  body.contact > .container > section.page > main > .profile .content,
  body.about > .container > section.page > main > .invitation .content,
  body.about > .container > section.page > main > .profile .content {
    margin-left: 50%;
    max-width: 500px; }
    body.contact > .container > section.page > main > .invitation .content header .heading,
    body.contact > .container > section.page > main > .invitation .content header .tagline,
    body.contact > .container > section.page > main > .profile .content header .heading,
    body.contact > .container > section.page > main > .profile .content header .tagline,
    body.about > .container > section.page > main > .invitation .content header .heading,
    body.about > .container > section.page > main > .invitation .content header .tagline,
    body.about > .container > section.page > main > .profile .content header .heading,
    body.about > .container > section.page > main > .profile .content header .tagline {
      font-size: 3.5em;
      font-family: "IM Fell DW Pica SC";
      line-height: .733em; }
      @media (max-width: 750px) {
        body.contact > .container > section.page > main > .invitation .content header .heading,
        body.contact > .container > section.page > main > .invitation .content header .tagline,
        body.contact > .container > section.page > main > .profile .content header .heading,
        body.contact > .container > section.page > main > .profile .content header .tagline,
        body.about > .container > section.page > main > .invitation .content header .heading,
        body.about > .container > section.page > main > .invitation .content header .tagline,
        body.about > .container > section.page > main > .profile .content header .heading,
        body.about > .container > section.page > main > .profile .content header .tagline {
          font-size: 2.5em; } }
    body.contact > .container > section.page > main > .invitation .content header .heading,
    body.contact > .container > section.page > main > .profile .content header .heading,
    body.about > .container > section.page > main > .invitation .content header .heading,
    body.about > .container > section.page > main > .profile .content header .heading {
      color: #776E66; }
    body.contact > .container > section.page > main > .invitation .content header p,
    body.contact > .container > section.page > main > .profile .content header p,
    body.about > .container > section.page > main > .invitation .content header p,
    body.about > .container > section.page > main > .profile .content header p {
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1.375em;
      letter-spacing: .1em; }
    body.contact > .container > section.page > main > .invitation .content p,
    body.contact > .container > section.page > main > .profile .content p,
    body.about > .container > section.page > main > .invitation .content p,
    body.about > .container > section.page > main > .profile .content p {
      margin-top: 3em;
      margin-bottom: 3em;
      line-height: 1.6875em; }
  body.contact > .container > section.page > main > .invitation .scene,
  body.contact > .container > section.page > main > .profile .scene,
  body.about > .container > section.page > main > .invitation .scene,
  body.about > .container > section.page > main > .profile .scene {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0; }
    body.contact > .container > section.page > main > .invitation .scene > div,
    body.contact > .container > section.page > main > .profile .scene > div,
    body.about > .container > section.page > main > .invitation .scene > div,
    body.about > .container > section.page > main > .profile .scene > div {
      position: absolute; }
  @media (max-width: 1100px) {
    body.contact > .container > section.page > main > .invitation .scene,
    body.contact > .container > section.page > main > .profile .scene,
    body.about > .container > section.page > main > .invitation .scene,
    body.about > .container > section.page > main > .profile .scene {
      width: 40%; }
    body.contact > .container > section.page > main > .invitation .content,
    body.contact > .container > section.page > main > .profile .content,
    body.about > .container > section.page > main > .invitation .content,
    body.about > .container > section.page > main > .profile .content {
      width: 55%;
      margin-left: 45%; } }

@media (max-width: 1100px) and (max-width: 1000px) {
  body.contact > .container > section.page > main > .invitation,
  body.contact > .container > section.page > main > .profile,
  body.about > .container > section.page > main > .invitation,
  body.about > .container > section.page > main > .profile {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: none;
    text-align: center; }
    body.contact > .container > section.page > main > .invitation .scene,
    body.contact > .container > section.page > main > .invitation .content,
    body.contact > .container > section.page > main > .profile .scene,
    body.contact > .container > section.page > main > .profile .content,
    body.about > .container > section.page > main > .invitation .scene,
    body.about > .container > section.page > main > .invitation .content,
    body.about > .container > section.page > main > .profile .scene,
    body.about > .container > section.page > main > .profile .content {
      width: auto;
      max-width: none;
      min-height: none;
      margin: 0;
      position: relative; }
    body.contact > .container > section.page > main > .invitation .content,
    body.contact > .container > section.page > main > .profile .content,
    body.about > .container > section.page > main > .invitation .content,
    body.about > .container > section.page > main > .profile .content {
      margin: auto;
      max-width: 600px;
      padding-left: 30px;
      padding-right: 30px; } }
    @media (max-width: 1100px) and (max-width: 1000px) and (max-width: 500px) {
      body.contact > .container > section.page > main > .invitation .content,
      body.contact > .container > section.page > main > .profile .content,
      body.about > .container > section.page > main > .invitation .content,
      body.about > .container > section.page > main > .profile .content {
        padding-left: 20px;
        padding-right: 20px; } }

@media (max-width: 1100px) and (max-width: 1000px) {
    body.contact > .container > section.page > main > .invitation .scene,
    body.contact > .container > section.page > main > .profile .scene,
    body.about > .container > section.page > main > .invitation .scene,
    body.about > .container > section.page > main > .profile .scene {
      order: 2; }
      body.contact > .container > section.page > main > .invitation .scene > div,
      body.contact > .container > section.page > main > .profile .scene > div,
      body.about > .container > section.page > main > .invitation .scene > div,
      body.about > .container > section.page > main > .profile .scene > div {
        position: relative; } }

@media (max-width: 1100px) and (max-width: 750px) {
  body.contact > .container > section.page > main > .invitation .content,
  body.contact > .container > section.page > main > .profile .content,
  body.about > .container > section.page > main > .invitation .content,
  body.about > .container > section.page > main > .profile .content {
    max-width: 500px; } }

body.contact > .container > section.page > main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  body.contact > .container > section.page > main > .banners ul li.email a span::before {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  body.contact > .container > section.page > main > .banners ul li.email a span {
    line-height: 1.7em; }
  body.contact > .container > section.page > main > .banners ul li.phone a span::before {
    font-family: "Jacco Wilbrink Graphics" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  body.contact > .container > section.page > main > .banners ul li.phone a span {
    line-height: 1.7em; }
  body.contact > .container > section.page > main > .banners ul li a span::before {
    display: inline-block; }
  @media (min-width: 1401px) {
    body.contact > .container > section.page > main > .banners ul li.flickr a span {
      margin-left: 30px;
      margin-top: 60px;
      text-align: left; } }
  body.contact > .container > section.page > main > .banners ul li.facebook a {
    background-image: url("images/contact/facebook.jpg"); }
  @media (max-width: 750px) {
    body.contact > .container > section.page > main > .banners ul li.social {
      display: none; } }
  body.contact > .container > section.page > main > .banners ul li.instagram a {
    background-image: url("images/contact/instagram.jpg"); }
  @media (max-width: 750px) {
    body.contact > .container > section.page > main > .banners ul li.flickr {
      order: 7; } }
  body.contact > .container > section.page > main > .banners ul li.flickr a {
    background-image: url("images/contact/flickr.jpg"); }
  body.contact > .container > section.page > main > .invitation {
    background-image: url("images/contact/invitation-bg.jpg");
    background-position: left;
    background-repeat: repeat-y;
    order: 2; }
    @media (max-width: 1000px) {
      body.contact > .container > section.page > main > .invitation {
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("images/contact/invitation-bg-alternative.jpg");
        background-position: bottom; } }
    body.contact > .container > section.page > main > .invitation .scene .laptop {
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("images/contact/laptop.png");
      width: 717px;
      height: 717px;
      background-size: 717px;
      top: -200px;
      right: 160px;
      z-index: 1;
      filter: drop-shadow(10px 10px 16px rgba(0, 0, 0, 0.3)); }
    body.contact > .container > section.page > main > .invitation .scene .card {
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("images/contact/card.png");
      width: 256px;
      height: 170px;
      background-size: 240px;
      top: 272px;
      right: 112px;
      z-index: 3;
      transform: translateY(24px) translateX(8px) scale(1.05); }
    body.contact > .container > section.page > main > .invitation .scene .mug {
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("images/contact/mug.png");
      width: 188px;
      height: 188px;
      background-size: 188px;
      right: 72px;
      top: -80px;
      z-index: 3; }
      body.contact > .container > section.page > main > .invitation .scene .mug:after {
        display: block;
        content: "";
        height: 100%;
        width: 100%;
        border-radius: 50%;
        box-shadow: -4px 10px 20px rgba(0, 0, 0, 0.35);
        z-index: -1; }

@keyframes animate-scene-cup {
  100% {
    transform: rotate(50deg); } }
    body.contact > .container > section.page > main > .invitation .scene.animate .mug {
      animation: animate-scene-cup .8s ease-out forwards; }

@keyframes animate-scene-laptop {
  100% {
    transform: translateX(-8px) translateY(-12px); } }
    body.contact > .container > section.page > main > .invitation .scene.animate .laptop {
      animation: animate-scene-laptop .5s ease-out forwards; }

@keyframes animate-scene-card {
  100% {
    transform: translateY(0) translateX(0) scale(1); } }
    body.contact > .container > section.page > main > .invitation .scene.animate .card {
      animation: animate-scene-card .5s ease-out forwards; }
    @media (max-width: 1300px) {
      body.contact > .container > section.page > main > .invitation .scene .laptop {
        right: 60px;
        z-index: 2; }
      body.contact > .container > section.page > main > .invitation .scene .card {
        right: 60px;
        z-index: 0;
        top: 340px; }
      body.contact > .container > section.page > main > .invitation .scene .mug {
        right: -39px;
        top: -142px; } }
    @media (max-width: 1000px) {
      body.contact > .container > section.page > main > .invitation .scene {
        margin-top: 60px;
        height: 450px; }
        body.contact > .container > section.page > main > .invitation .scene div {
          position: absolute;
          left: 60%; }
        body.contact > .container > section.page > main > .invitation .scene .laptop {
          height: 600px;
          background-size: 100%;
          width: 600px;
          margin-left: -475px;
          top: 0; }
        body.contact > .container > section.page > main > .invitation .scene .card {
          z-index: 3;
          width: 213px;
          height: 150px;
          background-size: 100%;
          margin-left: 10px;
          margin-top: -68px; }
        body.contact > .container > section.page > main > .invitation .scene .mug {
          margin-left: 50px;
          top: 60px;
          width: 155px;
          height: 155px;
          background-size: 100%; } }
    @media (max-width: 750px) {
      body.contact > .container > section.page > main > .invitation .scene div {
        left: 50%; } }
  @media (max-width: 750px) {
    body.contact > .container > section.page > main > .banners {
      margin-top: 20px;
      margin-bottom: 20px; }
    body.contact > .container > section.page > main > .invitation {
      order: initial; } }

@media (max-width: 750px) {
  body.about > .container > section.page > main > .banners {
    display: none; } }

body.about > .container > section.page > main > .banners ul li.wedding > span {
  background-image: url("images/about/wedding.jpg"); }

body.about > .container > section.page > main > .banners ul li.shoes > span {
  background-image: url("images/about/shoes.jpg"); }

body.about > .container > section.page > main > .banners ul li.belgian-ale > span {
  background-image: url("images/about/ale.jpg"); }

body.about > .container > section.page > main > .banners ul li.world-aware > span > span > span {
  margin-bottom: 0; }

body.about > .container > section.page > main > .profile {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("images/about/profile-bg.jpg");
  background-size: cover; }
  body.about > .container > section.page > main > .profile .scene .face {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("images/about/face.png");
    background-position: bottom;
    width: 547px;
    height: 547px;
    bottom: 0;
    right: 176px;
    transform: translateY(15%);
    background-size: auto 547px; }
  @media (max-width: 1300px) {
    body.about > .container > section.page > main > .profile .scene .face {
      right: 150px; } }
  @media (max-width: 1100px) {
    body.about > .container > section.page > main > .profile .scene .face {
      height: 550px;
      background-size: auto 550px;
      right: 20px; } }
  @media (max-width: 1000px) {
    body.about > .container > section.page > main > .profile .scene {
      height: 450px; }
      body.about > .container > section.page > main > .profile .scene .face {
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        height: 450px;
        background-size: auto 450px;
        width: auto;
        background-position: center center; } }
  @media (max-width: 750px) {
    body.about > .container > section.page > main > .profile .scene {
      height: 460px; }
      body.about > .container > section.page > main > .profile .scene .face {
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        height: 400px;
        background-size: auto 400px; } }
  @media (max-width: 400px) {
    body.about > .container > section.page > main > .profile .scene .face {
      background-position: right; } }

@keyframes animate-scene-face {
  100% {
    transform: translateY(0); } }
  body.about > .container > section.page > main > .profile .scene.animate .face {
    animation: animate-scene-face 1s ease-out forwards; }

body.error,
body.admin {
  background-color: white; }
  body.error > .container > section.page,
  body.admin > .container > section.page {
    min-height: 100vh;
    display: flex; }
    body.error > .container > section.page main,
    body.admin > .container > section.page main {
      margin: auto;
      text-align: center; }
      body.error > .container > section.page main h1,
      body.admin > .container > section.page main h1 {
        font-size: 3.5em;
        font-family: "IM Fell DW Pica SC"; }
        @media (max-width: 750px) {
          body.error > .container > section.page main h1,
          body.admin > .container > section.page main h1 {
            font-size: 3.125em; } }
      body.error > .container > section.page main p,
      body.admin > .container > section.page main p {
        font-size: 1.2em;
        line-height: 1.6em;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        margin-bottom: 60px; }
        @media (max-width: 750px) {
          body.error > .container > section.page main p,
          body.admin > .container > section.page main p {
            margin-top: 50px;
            margin-bottom: 50px; } }

body.admin .button {
  margin-bottom: 20px; }

body.sticky-nav > .container > header {
  background-color: rgba(255, 255, 255, 0.95);
  height: 88px;
  transition: top .3s, background-color .5s, height .3s; }
  body.sticky-nav > .container > header .logo {
    top: 20px;
    width: 224px;
    transition: width .5s, height .5s, opacity .5s; }
    body.sticky-nav > .container > header .logo svg {
      width: 100%;
      height: auto;
      transition: none; }
  @media (max-width: 1000px) {
    body.sticky-nav > .container > header {
      height: 105px; }
      body.sticky-nav > .container > header .logo {
        top: 25px;
        width: 268px;
        height: 55px; } }
  @media (max-width: 500px) {
    body.sticky-nav > .container > header {
      height: 76px; }
      body.sticky-nav > .container > header .logo {
        top: 20px;
        width: 195px;
        height: 40px; } }

/*
 * Viewport specific styles
 */
/* Oversized screens */
@media (min-width: 1991px) {
  .wrapper > .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px; } }


