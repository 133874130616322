body.intro {
	background-color: $light;
	
	@include spinner(32px, 4px, $dark);
	&::before {
		position: absolute;
		z-index: 2000;
		top: 50%;
		margin-top: -24px;
		left: 50%;
		margin-left: -16px;
		transition: top .75s, margin-top .5s;
	}
	@include mq('medium-width') {
		&::before {
			@include dimensions(30px);
			border-width: 3px;
		}
	}
	
	&.loaded {
		> .container {
			> header {
				.logo {
					top: 88px;
					opacity: 1;
					transition: top .75s ease-out, width .5s, height .5s, opacity .5s;
					
					&.loaded-animation {
						animation: fadeIn 1s forwards;
						animation-timing-function: ease-in;
					}
					
					@include mq('medium-width') {
						top: 80px;
					}
					@include mq('small-width2') {
						top: 70px;
					}
				}
			}
			> .page {
				animation: fadeIn 1s forwards;
			}
		}
	}
	
	> .container {
		background-color: transparent;
		
		> header {
			position: absolute;
			.logo {
				// Before body ready
				top: 30vh;
				top: calc(30vh - 60px);
				transform: translateX(-50%);
				opacity: 0;
			}
		}
		
		@include mq('small-width2') {
			> header {
				.logo {
					$height: 45px;
					$width: 365 * size-scale($height, 75);

					&,
					svg {
						height: $height;
						width: $width;
					}
				}
			}
		}

		> .page {
			// Before body ready
			opacity: 0;
			
			background-color: transparent;
			transition: opacity .5s;
			
			> .hero {
				z-index: 0;
				position: relative;
				top: 0;
				left: 0;
				right: 0;
				height: 100vh;
				min-height: 600px;
				box-sizing: border-box;
				padding-top: 50vh;

				@include mq('medium-height') {
					padding-top: 300px;
				}

				ul.slides {
					li.slide {
						@include cover;
					}
					&.active {
						li.slide {
							opacity: 0;
							
							@include spinner(32px, 4px, $dark);
							&::before {
								content: "";
								display: block;
								@include dimensions(50px);
								position: absolute;
								top:50%;
								left:50%;
							}
							
							&.transition {
								transition: opacity 1.5s;
							}
							&.active {
								opacity: 1;
							}
						}
					}
				}
				.dashboard {
					min-height: auto;

					@include mq('medium-width') {
						display: none;
					}

					.button {
						bottom: -80px;
					}
				}
				.overlay {
					z-index: 100;
					position: relative;
					margin-top: -3.3em;
					margin-left: auto;
					margin-right: auto;
					max-width: 750px;

					@include mq('medium-width2') {
						max-width: none;
						@include margin-horizontal(20px);
					}
				}
				.slides {
					.slide {
						background-size: cover;
						background-position: top;
						position: fixed;
					}
				}
			}
		}
		> footer {
			display: none;
		}
	}
}

body.intro,
body.gallery {
	> .container {
		background-color: transparent;
		
		> header {
			position: absolute;
		}
	}
}

body.intro .hero,
body.gallery .gallery {
	.dashboard {
		min-height: 470px;
		height: 50%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		.button {
			height: 48px;
			width: 48px;
			padding: 16px;
			display: block;
			text-align: center;
			display: flex;
			@include graphics-font;
			color: $light;
			text-shadow: 0px 0px 25px rgba(0, 0, 0, .2);
			background-color: transparent;
			font-size: 48px;
			position: absolute;
			z-index: 800;
			bottom: 0;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
			transition: padding .3s, background-color .5s, transform .1s, box-shadow .5s;
			animation: none;
			@media (min-height: 731px) {
				bottom: -40px;
			}
			&:hover {
				transform: initial;
				box-shadow: none;
				&.left {
					padding-left: 13px;
					padding-right: 19px;
				}
				&.right {
					padding-right: 13px;
					padding-left: 19px;
				}
			}
			&::before {
				margin: auto;
				display: block;
				content: "";
				animation: none;
				border: 0;
				transition: initial;
				position: static;
				opacity: 1;
				visibility: visible;
			}
			&.left {
				left: 8px;
				&::before {
					@include icon('arrow-previous');
				}
			}
			&.right {
				right: 8px;
				&::before {
					@include icon('arrow-next');
				}
			}
		}
	}
}

body.gallery .gallery {
	.dashboard {
		.button {
			@media (max-height: 730px) {
				&.left,
				&.right {
					bottom: initial;
					top: 50vh;
					transform: translateY(-50%);
				}
				&.right {
					right: 67px;
				}
			}
		}
	}
}

body.galleries_index,
body.galleries {
	> .container {
		> section.page {
			> main {
				a.button {
					@include margin-vertical(40px);
					font-weight: $bold;

					@include mq('small-width-gallery-index-min') {
						display: none;
					}

					&::after {
						content: ">";
						margin-left: .5em;
					}

					> .alternative {
						color: $medium;
					}
				}
			}
		}
	}
}

body.galleries_index,
body.galleries,
body.gallery,
body.contact,
body.about,
body.intro {
	>.container {
		> section.page {
			> .hero {
				.overlay {
					.button,
					.tagline {
						opacity: 0;
						transition: opacity .5s, transform 1s, background-color .5s, box-shadow .5s;
					}
					.button {
						transform: scale(.9);
					}
				}
				
				&.loaded {
					.overlay {
						.button,
						.tagline {
							opacity: 1;
						}
						.button {
							transform: scale(1);
						}
					}
				}
			}
		}
	}
}

body.galleries_index,
body.galleries,
body.gallery,
body.contact,
body.about {
	>.container {
		> section.page {
			> .hero {
				@include spinner(32px, 4px, $dark);
				position: relative;
				
				&::before {
					top: 50%;
					left:50%;
					margin-top:-15px;
					margin-left:-15px;
					position: absolute;
				}
				
				
				.image {
					opacity: 0;
					
					&.loaded {
						opacity: 1;
					}
				}
			}
		}
	}
}

body.galleries_index,
body.galleries,
body.gallery {
	> .container {
		> header {
			.logo {
				top: 32px;
				@include mq('medium-width') {
					top: 25px;
				}
				@include mq('small-width2') {
					top: 20px;
				}
			}
		}

		> section.page {
			padding-top: 128px;

			@include mq('medium-width') {
				padding-top: 105px;
			}
			@include mq('small-width2') {
				padding-top: 75px;
			}

			> .hero {
				padding: 0;
				height: 600px;
				background-color: $light-contrast;

				@include mq('desktop-width') {
					height: 30vw;
				}
				@include mq('medium-width2') {
					height: 33vw;
					margin: 0;
				}
			}
			> main {
				text-align: center;

				.overview {
					padding-bottom: 45px;

					@include mq('large-width') {
						padding-bottom: 30px;
					}
					@include mq('medium-width2') {
						padding-bottom: 20px;
					}
				}
			}
		}
	}
}

body.galleries_index,
body.galleries {
	> .container {
		> header {
			background-color: transparentize($light, .05);
		}
	}
}

body.galleries {
	> .container {
		main {
			@include mq('small-width-gallery-index-min') {
				margin: -1px;
			}

			> .galleries {
				margin-top: 2px;
				margin-bottom: 2px;

				padding-bottom: 45px;

				@include mq('large-width') {
					padding-bottom: 30px;
				}
				@include mq('medium-width2') {
					padding-bottom: 20px;
				}

				@include mq('small-width-gallery-index') {
					margin: 0px;
					padding: 0;
				}

				.row {
					@include mq('small-width-gallery-index-min') {
						display: flex;
						flex-direction: row;
					}

					@include mq('medium-width') {
						flex-wrap: wrap;
					}

					&.large-image {
						.column {
							height: 660px;

							@include mq('wide') {
								height: 36vw;
							}

							@include mq('medium-width') {
								@include mq('small-width-gallery-index-min') {
									max-width: 100%;
									width: 100%;
									flex-direction: row;
									height: auto;

									&.large {
										.item {
											width: 50%;
											max-width: 50%;
											height: 26vw;
										}
									}

									&:nth-child(2) {
										flex-wrap: wrap;
										flex-direction: row;
										position: relative;

										.item:nth-child(2) {
											width: 50%;
											max-width: 50%;
											max-width: calc(50% - 4.5px);
											position: absolute;
											right: 0;
											margin-top: -26vw;
											margin-top: calc(-26vw - 1px);
										}

										.item.text {
											width: 100%;
											max-width: 100%;
											flex-basis: 100%;
											order: 2;
										}
									}

									.item {
										width: 50%;
									}
								}
							}

							@include mq('small-width-gallery-index') {
								height: auto;
							}
						}

						@include mq('medium-width-min') {
							&:nth-child(2n-1) {
								.column {
									order: 1;
								}
								.column:first-child {
									order: 2;
								}
							}
						}
					}

					&.wide-text {
						.text {
							background-color: transparent;
							.tagline {
								text-transform: none;
								width: 90%;
								font-family: $alternative-font;
								@include font-scale(56);
								@include hash-before();
								margin: auto;
							}
							ul {
								display: none;
							}
						}

						@include mq('small-width-gallery-index') {
							display: none;
						}
					}

					&.regular {
						.item {
							max-width: (1 / 3) * 100%;
							max-width: calc((1 / 3) * 100% - 2px);
						}

						@include mq('medium-width') {
							@include mq('small-width-gallery-index-min') {
								.item {
									max-width: 50%;
									max-width: calc(50% - 2px);
									order: 1;

									&:first-child {
										max-width: 100%;
										flex-basis: 100%;
										order: 2;
									}
								}
							}
						}

						@include mq('small-width-gallery-index') {
							.item,
							.column {
								width: 100%;
								max-width: 100%;
							}
						}
					}
				}

				.item,
				.column {
					height: 330px;
					flex: 1;

					@include mq('wide') {
						height: 18vw;
					}
					@include mq('medium-width') {
						@include mq('small-width-gallery-index-min') {
							height: 26vw;
							&:first-child {
								height: 40vw;
							}
						}
					}
					@include mq('small-width-gallery-index') {
						height: auto;
						height: calc(55vw + 100px);
						overflow: visible;
					}
				}

				.column {
					display: flex;
					flex-direction: column;

					&.large {
						flex: 2;

						@include mq('medium-width') {
							flex-basis: 100%;
						}
					}

					@include mq('small-width-gallery-index') {
						display: block;
					}
				}

				.item {
					text-align: left;
					position: relative;
					margin: 1px;
					text-transform: uppercase;
					background-color: $light-contrast;
					&.text {
						display: flex;
						flex-flow: column;
						text-align: center;
						.tagline {
							@include font-scale(28);
							width: 50%;
							margin: auto;
							margin-bottom: 39px;

							@media (max-width: 1300px) and (min-width: 1001px) {
								margin-bottom: auto;
								@include font-scale(24);
							}
						}
						ul {
							margin: auto;
							margin-top: 0;
							width: 66%;
							@include font-scale(14);
							font-weight: $bold;

							li {
								line-height: 1.4em;
								
								a {
									&:hover {
										color: $medium;
									}
								}
							}

							@include mq('large-width-min') {
								li {
									display: inline-block;

									&::after {
										content: "|";
										margin-left: .5em;
										margin-right: .5em;
										color: $medium;
									}

									&:last-child::after {
										display: none;
									}
								}
							}
						}

						@include mq('small-width-gallery-index') {
							display: none;
						}
					}

					&.gallery {
						@include spinner(24px, 4px);
						transition: background-color 1s;
						
						&::before {
							top: calc(50% - 34px);
							@include mq('small-width-gallery-index') {
								top: calc(50% - 72px);
							}
						}
						
						&:hover {
							.image {
								filter: brightness(50%);
							}
						}
						
						.image {
							@include cover;
							background-size: cover;
							opacity: 0;
							transition: opacity 1s, filter 1s;

							@include mq('small-width-gallery-index') {
								height: 55vw;
							}
							
							&.visible {
								opacity: 1;
							}
						}
						
						&.loading-error {
							background-color: $medium;
						}
						
						.meta {
							background-color: transparentize($dark, .15);
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							padding: 16px;
							font-weight: $bold;
							@include font-scale(14);
							color: $light-contrast;

							.image-count {
								float: right;
								color: $medium-contrast;

								.count {
									color: $light-contrast;
								}
							}

							@include mq('small-width-gallery-index') {
								background-color: $dark;
								height: 100px;
								padding: 0;
								margin-top: 2px;
								@include font-scale(16);
								line-height: 28px;
								@include padding-vertical(22px);
								box-sizing: border-box;
								text-align: center;

								> span {
									display: block;
									white-space: nowrap;
								}

								.image-count {
									float: none;
									@include font-scale(14);
									color: $medium;

									.count {
										color: inherit;
									}
								}
							}
						}
					}

					@include mq('small-width-gallery-index') {
						margin: 0;
						margin-top: 20px;
					}
				}
			}
		}
	}
}

body.galleries_index {
	> .container {
		> section.page {
			> main {
				.overview {
					.categories {
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						@include margin-horizontal(-1px);
						margin-top: 1px;

						@include mq('large-width') {
							@include margin-horizontal(-10px);
							margin-top: 0px;
						}
						@include mq('small-width-gallery-index') {
							@include margin-horizontal(0px);
							margin-top: 0px;
						}

						li {
							display: block;
							width: (1 / 3) * 100%;
							display: block;
							overflow: hidden;
							flex-grow: 1;

							@include mq('large-width') {
								width: 50%;
								margin-top: 20px;
							}
							@include mq('small-width-gallery-index') {
								width: 100%;
							}

							a {
								@include margin-horizontal(1px);
								display: flex;
								flex-wrap: wrap;
								flex-direction: row;

								@include mq('large-width') {
									@include margin-horizontal(10px);

									&::before {
										order: 2;
									}
									&::after {
										order: 1;
									}
								}
								@include mq('small-width-gallery-index') {
									@include margin-horizontal(0);
								}

								&::before,
								&::after {
									transition: filter .5s, color .5s, background-color .5s;
								}
								
								&::before {
									display: block;
									height: 160px;
									background-color: $light;
									content: "";
									width: 100%;
									@include margin-vertical(1px);
									@include graphics-font();
									font-size: 200px;
									text-align: center;
									line-height: 160px;
									vertical-align: middle;
									overflow: hidden;
									color: $dark;
									font-weight: normal !important;

									@include mq('large-width') {
										height: 125px;
										line-height: 125px;
										font-size: 160px;
									}
									@include mq('small-width2') {
										height: 100px;
										line-height: 100px;
										font-size: 140px;
									}
								}
								&::after {
									display: block;
									height: 400px;
									min-height: 200px;
									content: "";
									width: 100%;
									@include margin-vertical(1px);
									background-size: cover;
									background-position: center;
									animation: fadeIn 1s forwards;
									opacity: 0;

									@include mq('desktop-width') {
										height: 20vw;
									}
									@include mq('medium-width2') {
										height: 26vw;
									}
									@include mq('small-width-gallery-index') {
										height: 55vw;
									}
								}

								span {
									display: none;
								}
								
								&:hover {
									&::after {
										filter: brightness(70%);
									}
									&:hover {
										&::before {
											color: $medium;
											background-color: $medium-contrast;
										}
									}
								}
							}

							&.automotive,
							&.purelife {
								a {
									&::before {
										order: 2;
									}
									&::after {
										order: 1;
									}
								}
							}

							&.purelife {
								a {
									&::before {
										@include icon('category-pure-life');
										background-color: $dark;
										color: $medium-contrast;
									}
									&::after {
										background-image: url('images/galleries/thumbs/purelife.jpg');
									}
								}
							}

							&.people {
								a {
									&::before {
										@include icon('category-people');
										background-color: $dark;
										color: $light;
									}
									&::after {
										background-image: url('images/galleries/thumbs/people.jpg');
									}
								}
							}

							&.animals {
								a {
									&::before {
										@include icon('category-animals');
										background-color: $medium;
										color: $light;
									}
									&::after {
										background-image: url('images/galleries/thumbs/animals.jpg');
									}
								}
							}

							&.commercial {
								a {
									&::before {
										@include icon('category-commercial');
										background-color: $light-contrast;
									}
									&::after {
										background-image: url('images/galleries/thumbs/commercial.jpg');
									}
								}
							}

							&.wedding {
								a {
									&::before {
										@include icon('category-wedding');
										background-color: $light-contrast;
									}
									&::after {
										background-image: url('images/galleries/thumbs/wedding.jpg');
									}
								}
							}

							&.automotive {
								a {
									&::before {
										@include icon('category-automotive');

										@include mq('large-width') {
											@include mq('medium-width2-min') {
												background-color: $light-greyscale;
											}
										}
									}
									&::after {
										background-image: url('images/galleries/thumbs/automotive.jpg');
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

body.gallery {
	$slider-view: '(min-width: 751px) and (min-height: 520px) and (orientation: landscape)';
	$linear-view-nav: '(max-width: 751px) and (min-height: 500px) and (orientation: portrait)';
	
	&.info-show {
		overflow: hidden;
	}

	> .container {
		@media #{$slider-view} {
			> header,
			> footer {
				display: none;
			}
		}
		> section.page {
			> main {
				nav {
					position: absolute;

					top: 87px;
					
					@media (max-height: 520px) and (min-width: 1000px) {
						top: 130px;
					}
					
					@media (max-width: 1000px) {
						top: 107px;
					}

					@include mq('small-width2') {
						top: 77px;
					}

					right: 0;
					z-index: 800;

					@media #{$linear-view-nav} {
						&.sticky {
							top: 2px;
							position: fixed;
						}
					}

					> a,
					> button,
					> span {
						background-color: $dark;
						color: $light-contrast;
						border: 0;
						font-family: inherit;
						font-size: inherit;
						margin: 0;
						padding: 0;
						@include font-scale(26);
						@include dimensions(60px);
						overflow: hidden;
						display: flex;
						margin-bottom: 2px;
						outline: 0;

						&::before {
							@include graphics-font;
						}

						span {
							margin: auto;
						}

						&.back {
							cursor: pointer;;
							&::before {
								@include icon('close');
								margin: auto;
								font-size: .85em;
							}
						}

						&.info {
							cursor: pointer;
							&::before {
								@include icon('info');
								margin: auto;
							}
						}
						
						&.back,
						&.info {
							&:hover {
								background-color: mix($dark, $medium-contrast, 80);
							}
						}

						&.index {
							color: $medium;
							display: none;

							@media #{$slider-view} {
								display: flex;
							}
						}

						&.index,
						&.length {
							font-family: "Roboto";
							font-weight: 300;
						}
					}
					@include mq('small-width3') {
						> a,
						> button,
						> span {
							font-size: 1.5em;
						}
					}
				}

				section.info {
					background-color: white;
					z-index: 2000;
					@include cover;
					text-align: left;
					overflow-y: auto;
					visibility: hidden;
					display: flex;
					flex-direction: column;
					opacity: 0;
					position: fixed !important;
					font-family: $main-font;
					
					.content {
						margin: auto;
						text-align: center;
						opacity: 0;
						@include padding-vertical(48px);
						@include margin-horizontal(48px);
						
						@include mq('medium-width2') {
							@include margin-horizontal(40px);
						}
						
						h1 {
							@include font-scale(56);
							font-family: $alternative-font;
							line-height: .733em;
							max-width: 400px;
							@include margin-horizontal(auto);
							
							@include mq('medium-width2') {
								@include font-scale(40);
							}
						}
						
						p {
							@include margin-vertical(3em);
							line-height: em-scale(27);
							max-width: 480px;
							@include margin-horizontal(auto);
							
							&:first-of-type {
								text-transform: uppercase;
								font-weight: $bold;
								line-height: em-scale(22);
								letter-spacing: .1em;
							}
						}
					}

					&.show {
						visibility: visible;
						animation: openMenu .5s forwards;
						
						.content {
							visibility: visible;
							animation: openMenu .5s forwards;
							animation-delay: .1s;
						}
					}
				}

				.gallery {
					text-align: center;

					.dashboard {
						display: none;

						@media #{$slider-view} {
							display: block;
						}
					}

					ul.slides {
						li.slide {
							position: relative;
							@include spinner(32px, 4px, $dark);
							user-select: none;
							
							&::before {
								position: absolute;
								top:50%;
								left:50%;
								margin-top:-20px;
								margin-left:-20px;
							}
							
							a {
								cursor: zoom-in;
							}

							img {
								width: 100%;
								height: auto;
								opacity: 0;
								transition: opacity .5s;
							}
							
							&.loaded {
								img {
									opacity: 1;
									height: auto !important;
								}
							}
						}
					}

					@media #{$slider-view} {
						ul.slides {
							&.active {
								@include cover;
								background-color: $dark-contrast;

								li.slide {
									@include cover;
									display: flex;
									overflow: hidden;
									opacity: 0;

									&.transition {
										transition: opacity .5s;
									}
									&.active {
										opacity: 1;
										z-index: 100;
										
										@include spinner(32px, 4px, $light);
										&::before {
											position: absolute;
										}
									}

									a {
										margin: auto;
										cursor: default;
									}
									img {
										width: auto;
										max-height: 100%;
										max-width: 100%;
										max-height: 100vh;
										max-width: 100vw;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

html {
	&:not(.no-js) {
		body.contact,
		body.about {
			> .container {
				> section.page {
					> main {
						@include mq('medium-width-min') {
							> section {
								opacity: 0;
								transform: translateY(30px);
								transition: transform .7s ease-out, opacity .5s ease-in;
								
								&.animate {
									opacity: 1;
									transform: translateY(0);
								}
							}
						}
					}
				}
			}
		}
	}
}

body.contact,
body.about {
	> .container {
		> header {
			@include mq('max-width: 900px') {
				background-color: transparentize($light, .05);
			}
			.logo {
				@include mq('max-width: 900px') {
					top: 25px;
				}
				@include mq('small-width2') {
					top: 20px;
				}
			}
		}
		> section.page {
			@include mq('max-width: 900px') {
				padding-top: 105px;
			}
			@include mq('small-width2') {
				padding-top: 75px;
			}

			> .hero {
				position: relative;
				.image {
					z-index: 0;
				}
				
				@include mq('large-width') {
					padding-top: 30vw;
					height: 25vw;
				}

				@include mq('medium-width') {
					padding-top: 25vw;
					height: 30vw;
				}

				@include mq('max-width: 900px') {
					padding: 0;
					height: 45vw;

					.tagline,
					.button {
						display: none;
					}
				}

				@include mq('small-width2') {
					padding: 0;
					height: 60vw;
					margin: 0;
				}
			}
			> main {
				> .banners {
					ul {
						display: flex;
						flex-wrap: wrap;
						flex-direction: column;
						padding: 2px;
						height: 480px;
						margin: -1px;

						@include mq('large-width') {
							height: 35vw;
						}
						@include mq('medium-width') {
							height: 60vw;
						}
						@include mq('medium-width2') {
							height: auto;
						}

						li {
							height: 160px;
							width: (1 / 3) * 100%;
							display: block;
							overflow: hidden;
							flex-grow: 1;
							text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
							letter-spacing: .1em;
						
							> a,
							> span {
								margin: 1px;
								display: flex;
								height: 160px;
								text-align: center;
								background-color: $medium-contrast;
								color: $light;
								@include padding-horizontal(24px);

								svg {
									width: auto;
									max-width:70%;
									max-height: 12.5vw;
									
									@include mq('medium-width2') {
										max-height: 30vw;
									}
								}

								span {
									line-height: .7em;
									display: block;
									margin: auto;
									font-weight: $bold;
									width: 100%;

									&::before {
										text-transform: none;
										@include graphics-font();
										margin-right: .75em;
										font-size: 1.2em;
										position: relative;
										top: .15em;
										color: $medium;
									}
								}
								text-transform: uppercase;
							}

							@include mq('large-width') {
								height: 15vw;
								> a,
								> span {
									height: 15vw;
								}
							}
							@include mq('medium-width') {
								width: 50%;
								height: 20vw;
								> a,
								> span {
									height: 20vw;
								}
							}
							@include mq('medium-width2') {
								width: 100%;
								height: 22vw;
								min-height: 100px;
								> a,
								> span {
									height: 22vw;
									min-height: 100px;
								}
							}

							&:nth-child(1) {
								order: 1;
							}

							&:nth-child(2) {
								order: 2;
								> a,
								> span {
									background-color: $light;
									color: $dark;
								}
							}

							&:nth-child(3) {
								order: 3;
								> a,
								> span {
									background-color: $medium;
								}
							}

							&:nth-child(4) {
								order: 4;
								> a,
								> span {
									background-color: $dark;
									color: $light-contrast;
								}
							}

							&:nth-child(5) {
								order: 5;
								> a,
								> span {
									background-color: $light-contrast;
									color: $dark;
								}
							}

							&:nth-child(6) {
								order: 6;
								> a,
								> span {
									background-color: $light;
									color: $dark;
								}

								@include mq('medium-width') {
									order: 4;
								}
							}

							&.large {
								height: 320px;
								> a,
								> span {
									height: 320px;
									background-size: cover;
									background-position: top;
								}

								@include mq('large-width') {
									height: 20vw;
									> a,
									> span {
										height: 20vw;
									}
								}

								@include mq('medium-width2') {
									height: 55vw;
									max-height: 300px;
									> a,
									> span {
										height: 55vw;
										max-height: 300px;
									}
								}
							}

							&.alternative {
								> a,
								> span {
									> span {
										font-weight: $regular;
										@include font-scale(18);

										> span {
											text-transform: none;
											font-family: $alternative-font;
											font-size: (em-scale(50) * (1em / em-scale(18em)))*1em;
											margin-bottom: 12px;

											@include mq('large-width') {
												font-size: 2.8em;
											}

											span {
												display: inline;
												color: $medium;
												text-transform: lowercase;
											}
										}

										span {
											font-weight: $regular;
										}
									}
								}

								&:nth-child(1),
								&:nth-child(2) {
									> a,
									> span {
										color: $medium-contrast;
										span {
											> span {
												color: $dark;
											}
										}
									}
								}
								&:nth-child(3),
								&:nth-child(4) {
									> a,
									> span {
										color: $medium;

										span {
											> span {
												color: $light-contrast;
											}
										}
									}
								}

								&:nth-child(5),
								&:nth-child(6) {
									> a,
									> span {
										color: $medium;

										> span > span {
											color: $dark;
										}
									}
								}
							}
						}
					}
				}
				> .invitation,
				> .profile {
					position: relative;
					overflow: hidden;
					@include padding-vertical(96px);
					min-height: 380px;

					@include mq('desktop-width') {
						min-height: 420px;
					}

					@include mq('medium-width') {
						padding-bottom: 0;
					}

					@include mq('medium-width2') {
						padding-top: 60px;
					}

					.content {
						margin-left: 50%;
						max-width: 500px;

						header {
							.heading,
							.tagline {
								@include font-scale(56);
								font-family: $alternative-font;
								line-height: .733em;

								@include mq('medium-width2') {
									@include font-scale(40);
								}
							}

							.heading {
								color: $medium;
							}

							p {
								text-transform: uppercase;
								font-weight: $bold;
								line-height: em-scale(22);
								letter-spacing: .1em;
							}
						}

						p {
							@include margin-vertical(3em);
							line-height: em-scale(27);
						}
					}

					.scene {
						position: absolute;
						width: 50%;
						top: 0;
						bottom: 0;
						> div {
							position: absolute;
						}
					}

					@include mq('max-width: 1100px') {
						.scene {
							width: 40%;
						}
						.content {
							width: 55%;
							margin-left: 45%;
						}

						@include mq('medium-width') {
							display: flex;
							flex-direction: column;
							flex-wrap: wrap;
							min-height: none;
							text-align: center;

							.scene,
							.content {
								width: auto;
								max-width: none;
								min-height: none;
								margin: 0;
								position: relative;
							}
							.content {
								margin: auto;
								max-width: 600px;
								@include padding-horizontal(30px);
								
								@include mq('small-width2') {
									@include padding-horizontal(20px);
								}
							}
							.scene {
								order: 2;

								> div {
									position: relative;
								}
							}
						}
						@include mq('medium-width2') {
							.content {
								max-width: 500px;
							}
						}
					}
				}
			}
		}
	}
}

body.contact {
	> .container {
		> section.page {
			> main {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				> .banners {
					ul {
						li {
							&.email {
								a span::before {
									@include icon('e-mail');
								}
								a span {
									line-height: 1.7em;
								}
							}

							&.phone {
								a span::before {
									@include icon('phone');
								}
								a span {
									line-height: 1.7em;
								}
							}

							a span::before {
								display: inline-block;
							}

							&.flickr {
								a {
									span {
										
										@media (min-width: 1401px) {
											margin-left: 30px;
											margin-top: 60px;
											text-align: left;
										}
									}
								}
							}

							&.facebook {
								a {
									background-image: url('images/contact/facebook.jpg');
								}
							}

							&.social {
								@include mq('medium-width2') {
									display: none;
								}
							}

							&.instagram {
								a {
									background-image: url('images/contact/instagram.jpg');
								}
							}

							&.flickr {
								@include mq('medium-width2') {
									order: 7;
								}

								a {
									background-image: url('images/contact/flickr.jpg');
								}
							}
						}
					}
				}

				> .invitation {
					background-image: url('images/contact/invitation-bg.jpg');
					background-position: left;
					background-repeat: repeat-y;
					order: 2;
					
					@include mq('medium-width') {
						@include bg-once('images/contact/invitation-bg-alternative.jpg');
						background-position: bottom;
					}

					.scene {
						.laptop {
							@include bg-once('images/contact/laptop.png');
							@include dimensions(717px);
							background-size: 717px;
							top: -200px;
							right: 160px;
							z-index: 1;
							filter: drop-shadow(10px 10px 16px rgba(0,0,0,.3));
						}

						.card {
							@include bg-once('images/contact/card.png');
							@include dimensions(256px, 170px);
							background-size: 240px;
							top: 272px;
							right: 112px;
							z-index: 3;
							transform: translateY(24px) translateX(8px) scale(1.05);
						}

						.mug {
							@include bg-once('images/contact/mug.png');
							@include dimensions(188px);
							background-size: 188px;
							right: 72px;
							top: -80px;
							z-index: 3;
							
							&:after {
								display: block;
								content: "";
								height: 100%;
								width: 100%;
								border-radius: 50%;
								box-shadow: -4px 10px 20px rgba(0, 0, 0, .35);
								z-index: -1;
							}
						}
						
						&.animate {
							@keyframes animate-scene-cup {
								100% {
									transform: rotate(50deg);
								}
							}
							
							.mug {
								animation: animate-scene-cup .8s ease-out forwards;
							}
							
							@keyframes animate-scene-laptop {
								100% {
									transform: translateX(-8px) translateY(-12px);
								}
							}
							
							.laptop {
								animation: animate-scene-laptop .5s ease-out forwards;
							}
							
							@keyframes animate-scene-card {
								100% {
									transform: translateY(0) translateX(0) scale(1);
								}
							}
							
							.card {
								animation: animate-scene-card .5s ease-out forwards;
							}
						}

						@include mq('large-width') {
							.laptop {
								right: 60px;
								z-index: 2;
							}
							.card {
								right:60px;
								z-index: 0;
								top: 340px;
							}
							.mug {
								right: -39px;
								top: -142px;
							}
						}

						@include mq('medium-width') {
							margin-top:60px;
							height: 450px;
							div {
								position: absolute;
								left: 60%;
							}
							.laptop {
								height: 600px;
								background-size: 100%;
								width: 600px;
								margin-left: -475px;
								top: 0;
							}
							.card {
								z-index: 3;
								width: 213px;
								height: 150px;
								background-size: 100%;
								margin-left: 10px;
								margin-top: -68px;
							}
							.mug {
								margin-left: 50px;
								top: 60px;
								width: 155px;
								height: 155px;
								background-size: 100%;
							}
						}

						@include mq('medium-width2') {
							div {
								left: 50%;
							}
						}
					}
				}
				
				@include mq('medium-width2') {
					> .banners {
						@include margin-vertical(20px);
					}
					
					> .invitation {
						order: initial;
					}
				}
			}
		}
	}
}

body.about {
	> .container {
		> section.page {
			> main {
				> .banners {
					@include mq('medium-width2') {
						display: none;
					}

					ul {
						li {
							&.wedding {
								> span {
									background-image: url('images/about/wedding.jpg');
								}
							}

							&.shoes {
								> span {
									background-image: url('images/about/shoes.jpg');
								}
							}

							&.belgian-ale {
								> span {
									background-image: url('images/about/ale.jpg');
								}
							}
							&.world-aware {
								> span > span > span {
									margin-bottom: 0;
								}
							}
						}
					}
				}

				> .profile {
					@include bg-once('images/about/profile-bg.jpg');
					background-size: cover;

					.scene {
						.face {
							@include bg-once('images/about/face.png');
							background-position: bottom;
							@include dimensions(547px);
							bottom: 0;
							right: 176px;
							transform: translateY(15%);
							background-size: auto 547px;
						}
						
						@include mq('large-width') {
							.face {
								right: 150px;
							}
						}
						
						@include mq('max-width: 1100px') {
							.face {
								height: 550px;
								background-size: auto 550px;
								right: 20px;
							}
						}
						
						@include mq('medium-width') {
							height: 450px;
							.face {
								margin-top: 60px;
								@include margin-horizontal(auto);
								height: 450px;
								background-size: auto 450px;
								width: auto;
								background-position: center center;
							}
						}
						
						@include mq('medium-width2') {
							height: 460px;
							.face {
								margin-top: 60px;
								@include margin-horizontal(auto);
								height: 400px;
								background-size: auto 400px;
							}
						}
						
						@include mq('small-width') {
							.face {
								background-position: right;
							}
						}
						
						&.animate {
							@keyframes animate-scene-face {
								100% {
									transform: translateY(0);
								}
							}
							
							.face {
								animation: animate-scene-face 1s ease-out forwards;
							}
						}
					}
				}
			}
		}
	}
}

body.error,
body.admin {
	background-color: white;
	> .container {
		> section.page {
			min-height: 100vh;
			display: flex;
			main {
				margin:auto;
				text-align: center;
				h1 {
					@include font-scale(56);
					font-family: $alternative-font;
					
					@include mq('medium-width2') {
						@include font-scale(50);
					}
				}
				p {
					font-size: 1.2em;
					line-height: 1.6em;
					max-width: 600px;
					@include margin-horizontal(auto);
					@include margin-vertical(60px);
					
					@include mq('medium-width2') {
						@include margin-vertical(50px);
					}
				}
			}
		}
	}
}

body.admin {
	.button {
		margin-bottom: 20px;
	}
}
