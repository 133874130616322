// Color scheme
$light: #FFFFFF;
$light-contrast: #ECE7E3;
$medium: #776E66;
$medium-contrast: #A49D95;
$dark: #1A1611;
$dark-contrast: #000000;
$light-greyscale: #FAFAFA;

// Fonts
$main-font: 'Roboto Condensed';
$alternative-font: 'IM Fell DW Pica SC';
$graphics-font: 'Jacco Wilbrink Graphics';
$base-font-size: 15px;
$regular: 400;
$bold: 700;

$media-queries: (
		'wide': 'max-width: 1990px',
		'desktop-width-min': 'min-width: 1601px',
		'desktop-width': 'max-width: 1600px',
		'large-width-footer-min': 'min-width: 1401px',
		'large-width-footer': 'max-width: 1400px',
		'large-width-min': 'min-width: 1301px',
		'large-width': 'max-width: 1300px',
		'medium-width': 'max-width: 1000px',
		'medium-width-min': 'min-width: 1001px',
		'medium-width2-min': 'min-width: 751px',
		'medium-width2': 'max-width: 750px',
		'medium-height': 'max-height: 600px',
		'small-width-gallery-index': 'max-width: 620px',
		'small-width-gallery-index-min': 'min-width: 621px',
		'small-width3': 'max-width: 600px',
		'small-width2': 'max-width: 500px',
		'small-width': 'max-width: 400px',
		'menu-small': 'max-width: 1100px',
);

$icons: (
		'arrow-next': '\e000',
		'arrow-previous': '\e001',
		'category-animals': '\e002',
		'category-automotive': '\e003',
		'category-commercial': '\e004',
		'category-people': '\e005',
		'category-pure-life': '\e006',
		'category-wedding': '\e007',
		'close': '\e008',
		'e-mail': '\e009',
		'facebook-automotive': '\e00a',
		'facebook-people': '\e00b',
		'flickr': '\e00c',
		'info': '\e00d',
		'instagram': '\e00e',
		'menu': '\e00f',
		'menu-about-me': '\e010',
		'menu-contact': '\e011',
		'menu-gallery': '\e012',
		'menu-intro': '\e013',
		'phone': '\e014',
		'web-label': '\e015'
);

// Mixins
@mixin pseudo-block {
  display: block;
  content: "";
}

@mixin pseudo-replace {
  position: absolute;
  left: 0;
  top: 0;
}

@mixin center {
  margin-left: auto;
  margin-right: auto;
}

@mixin absolute-center($width: 0) {
  position: absolute;
  left: 50%;
  margin-left: -($width/2);
}

@mixin bg-once($image) {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url($image);
}

@mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@function em-scale($size: 1) {
  @return (($size*1)/16) * 1em;
}

@function size-scale($size: 1, $original: 1) {
  @return $size / $original;
}

@mixin font-scale($size: 1) {
	font-size: em-scale($size);
}

@mixin float-group {
  zoom: 1; /* For IE 6/7 */
  &:before,
  &:after {
	content: "";
	display: table;
  }
  &:after {
	clear: both;
  }
}

@mixin margin-horizontal($value) {
  margin-left: $value;
  margin-right: $value;
}

@mixin margin-vertical($value) {
  margin-top: $value;
  margin-bottom: $value;
}

@mixin padding-horizontal($value) {
  padding-left: $value;
  padding-right: $value;
}

@mixin padding-vertical($value) {
  padding-top: $value;
  padding-bottom: $value;
}

@mixin button-reset() {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-indent: 200vw;
  overflow: hidden;
}

@mixin dimensions($width: 0, $height: $width) {
  width: $width;
  height: $height;
}

@mixin img-cover() {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;

  img {
	max-height: 101%;
	min-height: 100%;
	min-width: 101%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
}

@mixin mq($breakpoint) {
  $value: map-get($media-queries, $breakpoint);
  @if $value != null {
	@media (#{$value}) {
	  @content;
	}
  } @else {
	@media (#{$breakpoint}) {
	  @content;
	}
  }
}

@mixin icon($icon) {
	font-family: "Jacco Wilbrink Graphics" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	$content: map-get($icons, $icon);
	@if $content != null {
		content: $content;
	}
}