@charset "UTF-8";

@font-face {
	font-family: "Jacco Wilbrink Graphics";
	src: url("fonts/jaccowilbrink.eot");
	src: url("fonts/jaccowilbrink.eot?#iefix") format("embedded-opentype"),
	url("fonts/jaccowilbrink.woff") format("woff"),
	url("fonts/jaccowilbrink.ttf") format("truetype"),
	url("fonts/jaccowilbrink.svg#jaccowilbrink") format("svg");
	font-weight: normal;
	font-style: normal;
}

@import "reset";
@import "base";

@mixin hash-before() {
	&::before {
		content: '#';
		display: inline-block;
		vertical-align: top;
		font-size: .6em;
		margin-left: -.6em;
	}
}

@mixin graphics-font() {
	font-family: $graphics-font;
	font-weight: $regular !important;
	text-transform: none !important;
}

@mixin spinner($size, $width, $color: $light) {
	&::before,
	.spinner {
		user-select: none;
		display: block;
		content: "";
		color: $color;
		border: $width solid $color;
		border-top: $width solid transparent;
		width: $size;
		height: $size;
		border-radius: 50%;
		animation: delayedFadeIn .75s linear 0s 1, spin 1s linear infinite;
		margin: auto;
		position: relative;
		text-align: center;
		line-height: $size + 2 * $width;
		vertical-align: middle;
		font-size: $size;
		@include graphics-font();
	}
	&.loaded {
		&::before,
		.spinner {
			display: none;
		}
	}
	&.loading-error {
		&::before,
		.spinner {
			animation: none;
			@include icon('close');
			border-color: transparent;
		}
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes delayedFadeIn {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes openMenu {
	0% {
		opacity: 0;
		transform: scale(.95);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/*
 * Global stylesheet
 */
html, body {
	height: 100%;
	width: 100%;
}

body {
	font-size: $base-font-size;
	font-family: $main-font, sans-serif;
	font-weight: $regular;
	background-color: $light;
	color: $dark;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $light-contrast;
	min-width: 360px;
	zoom: 1;
	
	@include mq('small-width2') {
		font-size: 14px;
	}
	
	&.nav-open,
	&.info-show {
		.container {
			height: 100vh;
			overflow: hidden;
			> header {
				height: 0;
				/*.top {
					//position: fixed;
					//left: 0;
					//right: 0;
				}*/
				.logo {
					z-index: 100;
					opacity: 0 !important;
					animation: none !important;
				}
			}
		}
	}
	
	&.loaded {
		> .container {
			> header {
				.logo {
					transform: none;
					top: 88px;
					opacity: 1;
					
					@include mq('medium-width') {
						top: 80px;
					}
					@include mq('small-width2') {
						top: 70px;
					}
				}
			}
		}
		
	}
}

a {
	outline: 0;
}

body > .container {
	background-color: $dark;
	max-width: 2560px;
	min-width: 360px;
	box-shadow: 0 0 200px transparentize($dark, .7);
	margin-left: auto;
	margin-right: auto;
	min-height: 100%;
	
	> header {
		position: fixed;
		z-index: 2000;
		left: 0;
		right: 0;
		@include font-scale(20);
		text-shadow: 0px 0px 20px rgba(255, 255, 255, 1);
		height: 128px;
		transition: background-color .5s;
		
		@include mq('medium-width') {
			height: 105px;
		}
		@include mq('small-width2') {
			height: 75px;
		}
		
		.top {
			@include float-group();
			padding-top: 36px;
			z-index: 1000;
			position: relative;
			
			@include mq('medium-width') {
				padding-top: 30px;
			}
			@include mq('small-width2') {
				padding-top: 20px;
			}
			
			a.phone,
			button.menu {
				user-select: none;
				border: none;
				background-color: transparent;
				font-size: inherit;
				font-family: inherit;
				text-transform: uppercase;
				outline: 0;
				cursor: pointer;
				text-shadow: inherit;
				position: relative;
				display: inline-block;
				line-height: 1em;
				vertical-align: middle;
				
				@include mq('max-width: 650px') {
					text-indent: 100vw;
					white-space: nowrap;
					overflow: hidden;
					@include dimensions(30px);
					padding: 20px !important;
					box-sizing: content-box;
					margin-top: -20px;
					margin-left: -20px;
					margin-right: -20px;
				}
				
				&::before {
					content: '';
					display: block;
					@include graphics-font();
					color: $medium;
					font-size: 18px;
					text-transform: none;
					position: absolute;
					width: 1em;
					height: 1em;
					line-height: 1em;
					text-indent: 0;
					top: 0.06em;
					margin-left:auto;
					margin-right: auto;
					@include mq('max-width: 650px') {
						top: 20px;
						left: 20px !important;
						right: 20px !important;
						font-size: 23px;
					}
				}
				
				&:hover {
					&::before {
						color: $dark;
					}
				}
			}
			
			a.phone {
				padding-left: #{em-scale(19) + .6em};
				padding-left: calc(#{em-scale(19)} + 14px);
				
				&::before {
					@include icon('phone');
					left: 0;
				}
			}
			
			button.menu {
				@keyframes menuOpen {
					0% {
						@include icon('menu');
					}
					50% {
						@include icon('menu');
						transform: scale(0.05);
					}
					100% {
						@include icon('close');
					}
				}
				@keyframes menuClose {
					0% {
						@include icon('close');
					}
					50% {
						@include icon('close');
						transform: scale(0.05);
					}
					100% {
						@include icon('menu');
					}
				}
				
				float: right;
				padding-right: #{em-scale(19) + .6em};
				padding-right: calc(#{em-scale(19)} + 14px);
				
				&::before {
					@include icon('menu');
					right: 0;
				}
				
				&.closed {
					&::before {
						animation: menuClose .5s;
					}
				}
				
				&.open {
					&::before {
						animation: menuOpen .3s;
						@include icon('close');
						font-size: 16px;
					}
				}
			}
		}
		.logo {
			$width: 365px;
			$height: 62px;
			position: absolute;
			transform: translateX(-50%);
			z-index: 900;
			width: $width;
			left: 50%;
			text-align: center;
			z-index: 2000;
			transition: opacity .5s;
			transform-origin: 50% 50%;
			
			@include mq('medium-width') {
				$calc-width: $width * size-scale(55px, $height);
				width: $calc-width;
			}
			@include mq('small-width2') {
				$calc-width: $width * size-scale(40px, $height);
				width: $calc-width;
			}
			
			svg {
				width: $width;
				height: $height;
				
				@include mq('medium-width') {
					height: 55px;
					width: $width * size-scale(55px, $height);
				}
				@include mq('small-width2') {
					height: 40px;
					width: $width * size-scale(40px, $height);
				}
			}
		}
	}
	
	> footer {
		background-color: $dark;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
		color: $light;
		text-align: center;
		position: relative;
		
		section {
			@include padding-vertical(36px);
		}
		
		ul.social {
			@include mq('medium-width2') {
				margin-bottom: 16px;
				
				li a::before {
					font-size: 40px;
				}
			}
		}
		
		.numbers {
			color: $medium;
			background-color: $dark-contrast;
			text-transform: uppercase;
			letter-spacing: .1em;
			@include font-scale(20);
			line-height: 58px;
			user-select: none;
			
			@include mq('medium-width') {
				display: none;
			}
			
			.number {
				display: inline-block;
				@include margin-horizontal(32px);
				
				span {
					color: $light;
					font-weight: $bold;
					display: inline-block;
					text-align: right;
				}
			}
		}
		
		.contact {
			position: relative;
			
			@include mq('large-width-footer') {
				.content {
					@include margin-horizontal(32px);
					box-sizing: border-box;
				}
			}
			
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 112px;
				height: 112px;
				background-size: 112px 112px;
				top: -53px;
				left: 120px;
				
				@include mq('large-width-footer') {
					display: none;
				}
				@include mq('large-width-footer-min') {
					background-image: url('images/emblem-footer.png');
				}
			}
			
			a {
				transition: color .2s;
				&:hover {
					color: $medium;
				}
			}
			
			.tagline {
				display: block;
				@include font-scale(58);
				font-family: $alternative-font;
				color: $medium-contrast;
				@include hash-before();
				margin-bottom: 40px;
				margin-top: 20px;
				text-shadow: 0px 0px 25px rgba(0, 0, 0, .2);
				
				.alternative {
					color: $medium;
				}
				
				@include mq('medium-width2') {
					display: none;
				}
			}
			
			address, small {
				display: inline-block;
				font-weight: $bold;
				text-transform: uppercase;
				@include font-scale(12.5);
				letter-spacing: .1em;
				
				@include mq('medium-width') {
					display: block;
				}
				
				.year {
					display: none;
				}
				
				@include mq('small-width3') {
					.year {
						display: inline;
					}
					.author {
						display: none;
					}
				}
			}
			address {
				li {
					display: inline-block;
					
					&::after {
						content: '|';
						color: $medium-contrast;
						@include margin-horizontal(24px);
					}
					
					&:last-child {
						&::after {
							display: none;
						}
					}
					
					@include mq('medium-width') {
						display: block;
						line-height: 2.3em;
						
						&::after {
							display: none;
						}
					}
				}
			}
			small {
				color: $medium-contrast;
				
				&::before {
					content: '/';
					@include margin-horizontal(24px);
				}
				
				@include mq('large-width-footer') {
					margin-top: 1.75em;
					@include font-scale(11);
					display: block;
					
					&::before {
						display: none;
					}
				}
			}
		}
		
		a.to-top {
			@include font-scale(13);
			color: $medium;
			text-transform: uppercase;
			font-weight: $bold;
			transform: rotateZ(90deg) rotateY(180deg) rotateX(180deg) translateX(100%);
			transform-origin: center;
			display: block;
			position: absolute;
			right: 2px;
			right: calc(14px - 1em);
			bottom: 16px;
			user-select: none;
			
			@include mq('large-width-footer') {
				left: 20px;
				right: auto;
				bottom: 20px;
				transform: rotateZ(90deg) rotateY(180deg) rotateX(180deg) translateX(50%) translateY(-200%);
			}
			
			&:hover {
				color: $light-contrast;
			}
		}
		
		.web-label {
			background-image: url('images/web-label.svg');
			background-size: 25px 31px;
			height: 31px;
			width: 25px;
			background-repeat: no-repeat;
			display: block;
			z-index: 500;
			margin-left: auto;
			margin-right: auto;
			position: absolute;
			right: 16px;
			bottom: 0;
		}
	}
	
	> footer,
	> section.menu {
		.social {
			li {
				display: inline-block;
				
				a {
					display: block;
					text-indent: 200%;
					overflow: hidden;
					width: 44px;
					height: 44px;
					position: relative;
					color: $medium;
					transition: transform .3s;
					
					@include mq('medium-width2') {
						width: 50px;
						height: 50px;
					}
					
					&::before {
						content: '';
						display: block;
						@include graphics-font();
						text-indent: 0;
						font-size: 36px;
						position: absolute;
						top: 0;
						left: 0;
						text-align: center;
						padding: 4px;
					}
					
					&:hover {
						color: $light-contrast;
					}
				}
				
				&.instagram a::before {
					@include icon('instagram');
				}
				
				&.facebook-automotive a::before {
					@include icon('facebook-automotive');
				}
				
				&.facebook-people a::before {
					@include icon('facebook-people');
				}
				
				&.flickr a::before {
					@include icon('flickr');
				}
			}
		}
	}
	
	> section.menu {
		position: fixed;
		overflow: auto;
		background-color: $light;
		opacity: 0;
		visibility: hidden;
		@include cover;
		position: fixed;
		max-width: none;
		transition: opacity .25s, transform .2s;
		z-index: 900;
		
		.content {
			margin: auto;
			padding-top: 100px;
			padding-bottom: 50px;
			opacity: 0;
			transition: opacity 3s, background-color 5s;
			
			@include mq('menu-small') {
				padding-top: 100px;
				padding-bottom: 50px;
			}
		}
		
		&.open {
			display: flex;
			opacity: 1;
			visibility: visible;
			
			.content {
				animation: openMenu .5s forwards;
				animation-delay: .1s;
			}
		}
		
		.navigation {
			@include float-group();
			@include mq('menu-small') {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column-reverse;
			}
			
			.social, nav {
				width: 50%;
				float: left;
				@include padding-horizontal(72px);
				box-sizing: border-box;
				position: relative;
				
				@include mq('menu-small') {
					width: auto;
					@include padding-horizontal(0px);
				}
			}
			.social {
				text-align: right;
				
				.tagline {
					font-family: $alternative-font;
					@include font-scale(95);
					line-height: .57em;
					width: 320px;
					display: block;
					@include hash-before();
					color: $dark;
					word-wrap: break-word;
					-webkit-hyphenate-character: "";
					hyphenate-character: "";
					margin-bottom: 56px;
					user-select: none;
					
					&::before {
						font-size: .5em;
						margin-top: -.45em;
					}
					
					.alternative {
						color: $medium-contrast;
					}
				}
				
				@include mq('menu-small') {
					margin-top: 75px;
					text-align: center;
					
					.tagline {
						display: none;
					}
				}
				
				ul {
					li {
						display: inline-block;
						
						a {
							@include dimensions(36px);
							
							&::before {
								font-size: 30px;
								color: $medium-contrast;
							}
							
							&:hover {
								&::before {
									color: $medium;
									transform: translateY(-3px);
								}
							}
							
							@include mq('menu-small') {
								@include dimensions(50px);
								
								&::before {
									font-size: 42px;
									color: $medium;
								}
								
								&:hover {
									&::before {
										color: $dark;
										transform: initial;
									}
								}
							}
						}
					}
				}
				
				.tagline,
				ul {
					float: right;
					clear: both;
					
					@include mq('menu-small') {
						float: none;
					}
				}
			}
			nav {
				text-align: left;
				
				&::after {
					display: block;
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					border: 1px solid $medium-contrast;
					left: -1px;
					right: auto;
				}
				
				@include mq('menu-small') {
					&::after {
						display: none;
					}
				}
				
				ul {
					@include mq('menu-small') {
						padding-left: 18px;
					}
					li {
						font-size: 27px;
						text-transform: uppercase;
						display: block;
						margin-bottom: 48px;
						letter-spacing: .06em;
						white-space: nowrap;
						line-height: 38px;
						
						@include mq('small-width3') {
							font-size: 25px;
							margin-bottom: 35px;
						}
						
						&:last-child {
							margin-bottom: 0;
						}
						
						a {
							padding-left: 28px;
							&::before {
								@include graphics-font();
								color: $medium-contrast;
								position: relative;
								top: 0;
								display: block;
								float: left;
								font-size: 28px;
							}
							
							&::after {
								@include icon('arrow-next');
								display: inline-block;
								transition: transform .3s;
								margin-left: .5em;
								height: 1em;
								width: .5em;
								vertical-align: top;
								font-size: .5em;
								font-weight: bold;
							}
							&.galleries::before {
								@include icon('menu-gallery');
							}
							&.intro::before {
								@include icon('menu-intro');
							}
							&.about::before {
								@include icon('menu-about-me');
							}
							&.contact::before {
								@include icon('menu-contact');
							}
							
							&:hover {
								&::before {
									color: $medium;
								}
								&::after {
									transform: translateX(.2em);
								}
							}
						}
					}
				}
			}
		}
		
		footer {
			text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
			text-align: center;
			left: 0;
			right: 0;
			text-transform: uppercase;
			font-weight: $bold;
			letter-spacing: .1em;
			margin-top: 170px;
			
			@media (max-height: 1000px) {
				margin-top: 110px;
			}
			@media (max-height: 700px) {
				margin-top: 70px;
			}
			@media (max-height: 600px), (#{map-get($media-queries,'menu-small')}) {
				display: none;
			}
			
			address {
				@include font-scale(14);
				margin-bottom: 24px;
				
				ul {
					li {
						display: inline-block;
						
						&::after {
							content: "|";
							margin-left: 2em;
							margin-right: 2em;
							color: $medium-contrast;
						}
						
						&:last-child {
							&::after {
								display: none;
							}
						}
						
						a {
							transition: color .2s;
							&:hover {
								color: $medium;
							}
						}
					}
				}
			}
			
			small {
				@include font-scale(11);
				color: $medium-contrast;
			}
		}
	}
	
	> section.page {
		background-color: $light;
		overflow: hidden;
		
		.hero {
			padding-top: 320px;
			text-align: center;
			height: 290px;
			color: $light;
			white-space: pre-line;
			background-size: cover;
			background-position: center;
			background-position: top;
			
			.image {
				transition: opacity 1s;
				@include cover();
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}
			
			.overlay {
				.tagline,
				.button {
					z-index: 1;
					position: relative;
				}
			}
			
			.tagline {
				@include font-scale(58);
				padding-bottom: .06em;
				padding-top: .03em;
				@include padding-horizontal(.7em);
				font-family: $alternative-font;
				background-color: transparentize($dark, .4);
				clear: both;
				margin-bottom: 16px;
				@include hash-before();
				text-shadow: 0px 0px 25px rgba(0, 0, 0, .8);
				white-space: nowrap;
				
				@include mq('medium-width2') {
					font-size: 2.2em;
					font-size: 8vw;
					padding-top: .1em;
					padding-bottom: .2em;
				}
			}
			
			.button {
				margin-top: 1.4em;
				@include mq('medium-width2') {
					margin-top: 1.6em;
				}
			}
		}
	}
}

noscript.enable {
	text-align: center;
	color: $light;
	font-family: inherit;
	padding: 30px;
	display: block;
	margin:auto;
	position: fixed;
	bottom:0;
	left:0;
	right:0;
	background-color: darkred;
	height: 1em;
	line-height: 1em;
	vertical-align: middle;
	z-index: 2000;
}

.wrapper {
	max-width: 2560px;
	width: 100%;
	min-width: 360px;
	margin-left: auto;
	margin-right: auto;
}

.wrapper {
	> .content {
		@include margin-horizontal(36px);
		
		@include mq('large-width') {
			@include margin-horizontal(30px);
		}
		@include mq('medium-width2') {
			@include margin-horizontal(20px);
		}
	}
}

a.button,
button.button {
	border: 0;
	font-family: inherit;
	font-size: inherit;
	cursor: pointer;
	outline: 0;
	color: $light-contrast;
	background-color: $dark;
	text-transform: uppercase;
	@include padding-horizontal(em-scale(24));
	@include padding-vertical(em-scale(14));
	display: inline-block;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
	letter-spacing: .15em;
	@include font-scale(17);
	text-align: center;
	position: relative;
	z-index: 0;
	transition: background-color .5s, transform .1s, box-shadow .5s;
	
	@include mq('medium-width2') {
		font-size: 1.2em;
	}
	@include mq('small-width2') {
		font-size: 1.1em;
	}

	&:hover {
		background-color: transparentize($dark, .2);
		box-shadow: 0 0 60px 0 $dark-contrast inset;
	}
	
	&:active {
		transform: translateY(4px);
	}
}


/*
 * Page specific styles
 */
@import "pages";

body {
	&.sticky-nav {
		> .container {
			> header {
				background-color: transparentize($light, .05);
				height: 88px;
				transition: top .3s, background-color .5s, height .3s;
				
				.logo {
					top: 20px;
					width: 224px;
					transition: width .5s, height .5s, opacity .5s;
					
					svg {
						width: 100%;
						height: auto;
						transition: none;
					}
				}
				
				@include mq('medium-width') {
					height: 105px;
					
					.logo {
						top: 25px;
						width: 268px;
						height: 55px;
					}
				}
				@include mq('small-width2') {
					height: 76px;
					
					.logo {
						top: 20px;
						width: 195px;
						height: 40px;
					}
				}
			}
		}
	}
}

/*
 * Viewport specific styles
 */
@import "mediaqueries";
